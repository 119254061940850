import React from 'react';
import Link from '../components/Link';
import { Link as RouterLink } from '@reach/router';

const elements = {
  html: (props: any) => <html {...props} />,
  base: (props: any) => <base {...props} />,
  head: (props: any) => <head {...props} />,
  link: (props: any) => <link {...props} />,
  meta: (props: any) => <meta {...props} />,
  style: (props: any) => <style {...props} />,
  title: (props: any) => <title {...props} />,
  body: (props: any) => <body {...props} />,
  address: (props: any) => <address {...props} />,
  article: (props: any) => <article {...props} />,
  aside: (props: any) => <aside {...props} />,
  footer: (props: any) => <footer {...props} />,
  header: (props: any) => <header {...props} />,
  h1: (props: any) => <h1 {...props} />,
  h2: (props: any) => <h2 {...props} />,
  h3: (props: any) => <h3 {...props} />,
  h4: (props: any) => <h4 {...props} />,
  h5: (props: any) => <h5 {...props} />,
  h6: (props: any) => <h6 {...props} />,
  main: (props: any) => <main {...props} />,
  nav: (props: any) => <nav {...props} />,
  section: (props: any) => <section {...props} />,
  blockquote: (props: any) => <blockquote {...props} />,
  dd: (props: any) => <dd {...props} />,
  div: (props: any) => <div {...props} />,
  dl: (props: any) => <dl {...props} />,
  dt: (props: any) => <dt {...props} />,
  figcaption: (props: any) => <figcaption {...props} />,
  figure: (props: any) => <figure {...props} />,
  hr: (props: any) => <hr {...props} />,
  li: (props: any) => <li {...props} />,
  ol: (props: any) => <ol {...props} />,
  p: (props: any) => <p {...props} />,
  pre: (props: any) => <pre {...props} />,
  ul: (props: any) => <ul {...props} />,
  a: (props: any) => <a {...props} />,
  abbr: (props: any) => <abbr {...props} />,
  b: (props: any) => <b {...props} />,
  bdi: (props: any) => <bdi {...props} />,
  bdo: (props: any) => <bdo {...props} />,
  br: (props: any) => <br {...props} />,
  cite: (props: any) => <cite {...props} />,
  code: (props: any) => <code {...props} />,
  data: (props: any) => <data {...props} />,
  dfn: (props: any) => <dfn {...props} />,
  em: (props: any) => <em {...props} />,
  i: (props: any) => <i {...props} />,
  kbd: (props: any) => <kbd {...props} />,
  mark: (props: any) => <mark {...props} />,
  q: (props: any) => <q {...props} />,
  rp: (props: any) => <rp {...props} />,
  rt: (props: any) => <rt {...props} />,
  ruby: (props: any) => <ruby {...props} />,
  s: (props: any) => <s {...props} />,
  samp: (props: any) => <samp {...props} />,
  small: (props: any) => <small {...props} />,
  span: (props: any) => <span {...props} />,
  strong: (props: any) => <strong {...props} />,
  sub: (props: any) => <sub {...props} />,
  sup: (props: any) => <sup {...props} />,
  time: (props: any) => <time {...props} />,
  u: (props: any) => <u {...props} />,
  var: (props: any) => <var {...props} />,
  wbr: (props: any) => <wbr {...props} />,
  area: (props: any) => <area {...props} />,
  audio: (props: any) => <audio {...props} />,
  img: (props: any) => <img {...props} />,
  map: (props: any) => <map {...props} />,
  track: (props: any) => <track {...props} />,
  video: (props: any) => <video {...props} />,
  embed: (props: any) => <embed {...props} />,
  iframe: (props: any) => <iframe {...props} />,
  object: (props: any) => <object {...props} />,
  param: (props: any) => <param {...props} />,
  picture: (props: any) => <picture {...props} />,
  source: (props: any) => <source {...props} />,
  canvas: (props: any) => <canvas {...props} />,
  noscript: (props: any) => <noscript {...props} />,
  script: (props: any) => <script {...props} />,
  del: (props: any) => <del {...props} />,
  ins: (props: any) => <ins {...props} />,
  caption: (props: any) => <caption {...props} />,
  col: (props: any) => <col {...props} />,
  colgroup: (props: any) => <colgroup {...props} />,
  table: (props: any) => <table {...props} />,
  tbody: (props: any) => <tbody {...props} />,
  td: (props: any) => <td {...props} />,
  tfoot: (props: any) => <tfoot {...props} />,
  th: (props: any) => <th {...props} />,
  thead: (props: any) => <thead {...props} />,
  tr: (props: any) => <tr {...props} />,
  button: (props: any) => <button {...props} />,
  datalist: (props: any) => <datalist {...props} />,
  fieldset: (props: any) => <fieldset {...props} />,
  form: (props: any) => <form {...props} />,
  input: (props: any) => <input {...props} />,
  label: (props: any) => <label {...props} />,
  legend: (props: any) => <legend {...props} />,
  meter: (props: any) => <meter {...props} />,
  optgroup: (props: any) => <optgroup {...props} />,
  option: (props: any) => <option {...props} />,
  output: (props: any) => <output {...props} />,
  progress: (props: any) => <progress {...props} />,
  select: (props: any) => <select {...props} />,
  textarea: (props: any) => <textarea {...props} />,
  details: (props: any) => <details {...props} />,
  dialog: (props: any) => <dialog {...props} />,
  menu: (props: any) => <menu {...props} />,
  summary: (props: any) => <summary {...props} />,
  slot: (props: any) => <slot {...props} />,
  template: (props: any) => <template {...props} />,
  big: (props: any) => <big {...props} />,
  hgroup: (props: any) => <hgroup {...props} />,
  image: (props: any) => <image {...props} />,
  keygen: (props: any) => <keygen {...props} />,
  menuitem: (props: any) => <menuitem {...props} />,
  Link: (props: any) => <Link {...props} />,
  RouterLink: (props: any) => <RouterLink {...props} />,
  // Image: (props: any) => <Image {...props} />,
}

export type As = keyof typeof elements
export { elements }
