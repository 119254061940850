import React, { useCallback } from 'react';
import Card from '../../components/Card';
import AvailableBalance from '../../components/AvailableBalance';

import './Balance.scss';
import PeriodRange from '../../components/PeriodRange';
import BlockList, { BlockListItem } from '../../components/BlockList';
import { withCurrency } from '../../util/currency';
import Button from '../../components/Button';
import SlideTransitionRouter from '../../components/SlideTransitionRouter';
import BalanceBreakdown from '../sharedRoutes/BalanceBreakdown';
import PendingTransaction from '../sharedRoutes/PendingTransaction';
import Transaction from '../sharedRoutes/Transaction';
import Icon from '../../components/Icon';
import BlockListTransactions from '../../components/BlockList/BlockListTransactions';
import Budget from '../sharedRoutes/Budget';
import { useFinancialPeriod, useFinancialPeriodMethods } from '../../providers/FinancialPeriod';
import { useNavigate } from '@reach/router';
import BlockListBudget from '../../components/BlockList/BlockListBudget';
import Saving from '../sharedRoutes/Saving';
import BlockListSaving from '../../components/BlockList/BlockListSaving';
import SavingTransaction from '../sharedRoutes/SavingTransaction';
import { useActiveAccount } from '../../hooks/useActiveAccount';
import Box from '../../components/Box';

const BACK = {
  label: 'Back to balance',
  href: '/balance'
};

const Balance: React.FC = () => {
  const navigate = useNavigate();
  const account = useActiveAccount();
  const { data } = useFinancialPeriod();
  const { loadTransactions, setRemoveRemainingBudgetsFromBalance } = useFinancialPeriodMethods();
  const { from, to, transactions, budgets, savings, balance } = data;

  const handleTransactionSaved = useCallback(() => {
    navigate(`..?account=${account}`);
    loadTransactions();
  }, [navigate, account, loadTransactions]);

  const handleBalanceBreakdownBack = () => {
    setRemoveRemainingBudgetsFromBalance(false);
  };

  return (
    <main className='Balance_Page'>
      <AvailableBalance {...data} />

      <Box className='Balance_PageBox'>
        <Card
          as='section'
          className='Balance_Section'
          header={<PeriodRange from={from} to={to} icon='DateRange' />}
          footer={(
            <Button
              as='Link'
              to='pending-transaction/new'
              modifiers={['block']}
            >
              Create expected transaction
            </Button>
          )}
        >
          <h3 className='Card_Heading'>Spending budgets</h3>
          <BlockList className='Balance_SectionList'>
            {!budgets?.length && (
              <BlockListItem hasChevron={false}>
                No budgets set up...
              </BlockListItem>
            )}
            {budgets?.map((budget) => {
              return (
                <BlockListBudget
                  key={budget._id}
                  to={`budget/${budget._id}`}
                  budget={budget}
                />
              )
            })}
          </BlockList>

          <h3 className='Card_Heading'>Pending transactions</h3>
          <BlockListTransactions
            transactions={transactions.pendingTransactions}
          />
        </Card>

        <Card
          as='section'
          className='Balance_Section'
          footer={(
            <Button
              modifiers={['block']}
              as='RouterLink'
              to={`/accounts/${account}/saving`}
            >
              Manage saving pots
            </Button>
          )}
          header={<Icon name='Savings' />}
        >
          <h3 className='Card_Heading'>Saving pots</h3>
          <BlockList className='Balance_SectionList'>
            {!savings?.length && (
              <BlockListItem hasChevron={false}>
                No saving pots...
              </BlockListItem>
            )}
            {savings?.map((saving) => {
              return (
                <BlockListSaving
                  key={saving._id}
                  to={`saving/${saving._id}`}
                  saving={saving}
                />
              )
            })}
          </BlockList>
        </Card>
      </Box>

      <SlideTransitionRouter>
        <BalanceBreakdown
          path='balance-breakdown'
          back={BACK}
          onBack={handleBalanceBreakdownBack}
          startBalanceLabel='Current bank balance'
        />
        <Budget
          path='budget/:budgetId/*'
          back={BACK}
        />
        <Saving
          path='saving/:savingId/*'
          back={BACK}
        />
        <Transaction
          path='transaction/:transactionId'
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
        <PendingTransaction
          path='pending-transaction/:transactionId'
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
        <SavingTransaction
          path='saving-transfer/:transactionId'
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
      </SlideTransitionRouter>
    </main>
  );
}

export default Balance;
