import React from 'react';
import BlockListItem, { BlockListItemProps } from './BlockListItem';
import { withCurrency } from '../../util/currency';

import './BlockListTransaction.scss';

export type BlockListTransactionProps = Omit<BlockListItemProps, 'onClick'> & {
  transaction: BRData.Transaction
  reverseValue?: boolean
  onClick?: (transaction: BRData.Transaction) => void
}

const BlockListTransaction: React.FC<BlockListTransactionProps> = ({
  transaction,
  reverseValue,
  onClick,
  className = '',
  children,
  ...props
}) => {
  return (
    <BlockListItem
      className={`BlockListTransaction ${className}`}
      right={(
        <span className='BlockListTransaction_Amount'>
          {withCurrency(reverseValue ? 0 - transaction.amount : transaction.amount)}
        </span>
      )}
      onClick={() => onClick?.(transaction)}
      {...props}
    >
      <span className='BlockListTransaction_Title'>{transaction.title}</span>
      <span className='BlockListTransaction_Subtitle'>{transaction.subtitle}</span>
      {children}
    </BlockListItem>
  )
};

export default BlockListTransaction;
