import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.scss';

interface Props extends BR.FormControlProps {
}

const DateInput: React.FC<Props> = (props) => {
  const {
    register,
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled
  } = props;
  const { formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <>
      <label className='DateInput'>
        <span className='DateInput_Label'>{label}</span>
        <Controller
          name={name}
          rules={validation}
          render={({field}) => {
            const { onChange, onBlur, value } = field;
            return (
              <DatePicker
                disabled={disabled}
                selected={value ? new Date(value) : undefined}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                locale="en-GB"
                dateFormat="do MMM yyyy"
              />
            );
          }}
        />
        {/* <input
          className='DateInput_Control'
          type='text'
          name={name}
          id={id}
          {...register(name, {
            onChange,
            onBlur,
            onFocus,
            disabled,
            // value: new Date(value),
            valueAsDate: true,
            ...validation
          })}
        /> */}
      </label>
      {error && (
        <p className='DateInput_ErrorMessage'>{error.message}</p>
      )}
    </>
  )
}

export default DateInput;
