import formatDate from 'date-fns/format';
import { withCurrency } from '../../util/currency';
import Icon from '../Icon';
import Link from '../Link';

import './AvailableBalance.scss';

const AvailableBalance: React.FC<BRData.FinancialPeriodData> = (props) => {
  const { to, balance, savings } = props
  const { accountBalance, availableBalance, savingTotal } = balance;
  return (
    <Link className='AvailableBalance' to='./balance-breakdown'>
      <div className="AvailableBalance_Header">
        <h1>{formatDate(new Date(), 'EEE do MMMM')}</h1>
        <p className='text-right'>
          Period ends <br /><b>{formatDate(new Date(to), 'EEE do MMMM')}</b>
        </p>
      </div>
      <dl className='AvailableBalance_Balances'>
        <dt>Bank balance</dt>
        <dd>{withCurrency(accountBalance)}</dd>

        {!!savings?.length && (
          <>
            <dt>Saving pots</dt>
            <dd>{withCurrency(savingTotal)}</dd>
          </>
        )}

        <dt><b>Available balance</b></dt>
        <dd className='AvailableBalance_Balance'>
          <b>{withCurrency(availableBalance)}</b>
          <Icon name='ChevronRight' />
        </dd>
      </dl>

    </Link>
    
  )
}

export default AvailableBalance;
