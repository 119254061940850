import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App, { NoAuthState } from './App';
import AuthProvider from './providers/auth';
import reportWebVitals from './reportWebVitals';

import './styles/index.scss';
import { Router } from '@reach/router';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_DOMAIN as string}
      clientId={process.env.REACT_APP_CLIENT_ID as string}
      audience={process.env.REACT_APP_AUDIENCE}
      redirectUri={window.location.origin}
      cacheLocation='localstorage'
      useRefreshTokens={true}
    >
      <AuthProvider>
        <Router>
          <NoAuthState path='/sign-in' />
          <App path='/*' />
        </Router>
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
