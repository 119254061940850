import { Redirect, RouteComponentProps } from '@reach/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useApi } from '../../../api/useApi';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import BlockListBudget from '../../../components/BlockList/BlockListBudget';
import BlockListSaving from '../../../components/BlockList/BlockListSaving';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Icon from '../../../components/Icon';
import SlideRoute from '../../../components/SlideRoute';
import SlideTransitionRouter from '../../../components/SlideTransitionRouter';
import Spinner from '../../../components/Spinner';
import AccountProvider from '../../../providers/Account';
import { useAccounts } from '../../../providers/Accounts';
import FinancialPeriodProvider from '../../../providers/FinancialPeriod';
import BudgetSettings from '../../sharedRoutes/BudgetSettings';
import SavingSettings from '../../sharedRoutes/SavingSettings';

import './Account.scss';

type AccountProps = BR.SlideRoutePathProps
  & RouteComponentProps
  & {
    accountId?: string;
}

const Account: React.FC<AccountProps> = ({back, accountId, navigate, ...props}) => {
  const accounts = useAccounts();
  const account = useMemo(() => accounts.find(({account_id}) => account_id === accountId), [accountId, accounts]);

  const date = useMemo(() => new Date(), []);

  const [ timePeriod, loadTimePeriod ] = useApi<[string, string]>(`timeline?date=${date}`, true);
  const [ balance, loadBalance ] = useApi<TLData.AccountBalance>(`balance?account=${account?.account_id}`, true);
  const [ budgets, loadBudgets ] = useApi<BRData.Budget[]>(`budgets?account=${account?.account_id}`, true);
  const [ savings, loadSavings ] = useApi<BRData.Savings[]>(`savings?account=${account?.account_id}`, true);

  useEffect(() => {
    loadTimePeriod();
    loadBalance();
    loadBudgets();
    loadSavings();
  }, [loadTimePeriod, loadBalance, loadBudgets, loadSavings]);

  const handleSavingSubmit = useCallback(() => {
    navigate?.('./');
  }, [navigate]);

  const handleBudgetSubmit = useCallback(() => {
    navigate?.('./');
  }, [navigate]);

  const isLoading = budgets.loading || savings.loading || balance.loading;

  if (!account) {
    return (
      <Redirect to='/accounts' />
    )
  }

  return (
    <SlideRoute back={back}>
      {isLoading ? (
        <Spinner className='Account_PageLoading' />
      ) : (
        <FinancialPeriodProvider
          account={account?.account_id}
          balance={balance.data.available}
          from={timePeriod.data[0]}
          to={timePeriod.data[1]}
          budgets={budgets.data}
          savings={savings.data}
          includePendingBeforeFromDate={true}
          onLoading={<Spinner className='Balance_PageSpinner' />}
        >
          <Box className='Account_Header'>
            <img className='Account_ProviderLogo' src={account?.provider.logo_uri} alt={account?.provider.display_name} />
            <h1>{account?.provider.display_name}</h1>
          </Box>
          <Box>
            <dl className='Account_AccountDetails'>
              <dt>Account name:</dt>
              <dd>{account?.display_name}</dd>
              <dt>Account number:</dt>
              <dd>{account?.account_number.number}</dd>
              <dt>Sort code:</dt>
              <dd>{account?.account_number.sort_code}</dd>
            </dl>
          </Box>
          <Card
            as='section'
            className='Account_Section'
            modifiers={['full']}
            footer={(
              <Button
                modifiers={['block']}
                as='RouterLink'
                to={`saving/new?newSBankAccountId=${account?.account_id}`}
              >
                Create saving pot
              </Button>
            )}
            header={<Icon name='Savings' />}
          >
            <h3 className='Card_Heading'>Saving pots</h3>
            <BlockList className='Account_SectionList'>
              {!savings.data?.length && (
                <BlockListItem hasChevron={false}>
                  No saving pots...
                </BlockListItem>
              )}
              {savings.data?.map((saving) => {
                return (
                  <BlockListSaving
                    key={saving._id}
                    to={`saving/${saving._id}`}
                    saving={saving}
                  />
                )
              })}
            </BlockList>
          </Card>

          <Card
            as='section'
            className='Account_Section'
            modifiers={['full']}
            footer={(
              <Button
                modifiers={['block']}
                as='RouterLink'
                to={`budget/new?newBBankAccountId=${account?.account_id}`}
              >
                Create budget category
              </Button>
            )}
          >
            <h3 className='Card_Heading'>Budgets</h3>
            <BlockList className='Account_SectionList'>
              {!budgets.data?.length && (
                <BlockListItem hasChevron={false}>
                  No budgets...
                </BlockListItem>
              )}
              {budgets.data?.map((budget) => {
                return (
                  <BlockListBudget
                    key={budget._id}
                    to={`budget/${budget._id}`}
                    budget={budget}
                  />
                )
              })}
            </BlockList>
          </Card>

          <AccountProvider account={account} savings={savings?.data} budgets={budgets?.data}>
            <SlideTransitionRouter>
              <SavingSettings
                path='saving/:savingId'
                back={{label: `Back to ${account?.provider.display_name} account`, href: '../'}}
                onSubmit={handleSavingSubmit}
              />
              <BudgetSettings
                path='budget/:budgetId'
                back={{label: `Back to ${account?.provider.display_name} account`, href: '../'}}
                onSubmit={handleBudgetSubmit}
              />
            </SlideTransitionRouter>
          </AccountProvider>
        </FinancialPeriodProvider>
      )}
    </SlideRoute>
  )
};

export default Account;