import React, { ReactNode } from 'react';
import Element, { As } from '../Element';

import './Card.scss';

const Card: React.FC<{
  header?: ReactNode,
  footer?: ReactNode,
  className?: string,
  modifiers?: string[],
  as?: As
}> = ({
  children,
  header,
  footer,
  className = '',
  modifiers = [],
  as = 'div'
}) => {
  return (
    <Element as={as} className={`Card ${className}${modifiers.map(m => ` -${m}`)}`}>
      {header && (
        <div className='Card_Header'>
          {header}
        </div>
      )}
      {children}
      {footer && (
        <div className='Card_Footer'>
          {footer}
        </div>
      )}
    </Element>
  )
};

export default Card;