import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CheckboxToggleInput, { CheckboxToggleInputProps } from '../CheckboxToggleInput';

import { currencyToInt, intToCurrency, withCurrency } from '../../../util/currency';

import './TransactionAmount.scss';

export interface TransactionAmountProps extends BR.FormControlProps {
  toggleProps?: Partial<CheckboxToggleInputProps>;
}

const TransactionAmount: React.FC<TransactionAmountProps> = (props) => {
  const {
    register,
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled,
    className = '',
    toggleProps
  } = props;
  const { watch, setValue, formState } = useFormContext();
  const error = formState.errors[name];

  const value = watch(name);
  const isOut = value < 0;
  const [faceValue, setFaceValue] = useState<string>(withCurrency(isOut ? -value : value));

  useEffect(() => {
    setFaceValue(withCurrency(isOut ? -value : value));
  }, [isOut, value]);

  const handleToggleChange = useCallback(() => {
    setValue(name, -value);
  }, [name, setValue, value]);

  const handleInputFocus = useCallback((e) => {
    setFaceValue(intToCurrency(isOut ? -value : value));
    onFocus?.(e);
    e.target.select?.();
  }, [value, isOut, onFocus]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFaceValue(e.target.value);
    onChange?.(e);
  }, [onChange]);

  const handleInputBlur = useCallback((e) => {
    setValue(name, isOut ? -currencyToInt(e.target.value) : currencyToInt(e.target.value), { shouldValidate: true });
    setFaceValue(withCurrency(isOut ? -value : value));
    onBlur?.(e);
  }, [setValue, name, isOut, value, onBlur]);
  
  return (
    <>
      <div className={`TransactionAmount ${className}`}>
        <label className='TransactionAmount_Field'>
          <span className='TransactionAmount_Label'>{label}</span>
          <input type="hidden"
            name={name}
            id={id}
            {...register(name, {
              valueAsNumber: true,
              ...validation
            })}
          />
          <input
            className='TransactionAmount_Control'
            inputMode='numeric'
            type='text'
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            value={faceValue}
            disabled={disabled}
          />
        </label>
        <CheckboxToggleInput
          onText='IN'
          offText='OUT'
          className='TransactionAmount_Toggle'
          {...toggleProps}
          value='out'
          checked={value > 0}
          onChange={handleToggleChange}
          disabled={disabled}
        />
      </div>
      {error && (
        <p className='TransactionAmount_ErrorMessage'>{error.message}</p>
      )}
    </>
  )
}

export default TransactionAmount;
