import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import addDays from 'date-fns/addDays';

import './Timeline.scss';

import { useApi } from '../../api/useApi';
import { useActiveAccount } from '../../hooks/useActiveAccount';
import Spinner, { SimpleSpinner } from '../../components/Spinner';
import FinancialPeriodProvider from '../../providers/FinancialPeriod';

import TimelinePeriod from './TimelinePeriod';
import { useAccessToken } from '../../providers/auth';

const Timeline: React.FC<BR.RoutePathProps & RouteComponentProps> = () => {
  const { data: accessToken } = useAccessToken();
  const date = useMemo(() => new Date(), []);
  const account = useActiveAccount();

  const [ timePeriod, loadTimePeriod ] = useApi<[string, string]>(`timeline?date=${date}`, true);
  const [ balance, loadBalance ] = useApi<TLData.AccountBalance>(`balance?account=${account}`, true);
  const [ budgets, loadBudgets ] = useApi<BRData.Budget[]>(`budgets?account=${account}`, true);
  const [ savings, loadSavings ] = useApi<BRData.Savings[]>(`savings?account=${account}`, true);

  useEffect(() => {
    loadTimePeriod();
    loadBalance();
    loadBudgets();
    loadSavings();
  }, [loadTimePeriod, loadBalance, loadBudgets, loadSavings]);

  const [futureDates, setFutureDates] = useState<[string, string][]>([]);
  const [futureBalances, setFutureBalances] = useState<BRData.Balance[]>([]);
  const [pastDates, setPastDates] = useState<[string, string][]>([]);
  
  const [currentPeriod, setCurrentPeriod] = useState<BRData.FinancialPeriodData | null>(null);

  const loadFutureTimeline = () => {
    const latest = futureDates[futureDates?.length - 1]?.[1] || timePeriod.data?.[1];
    if (latest) {
      const nextReference = addDays(new Date(latest), 1);
      fetch(`${process.env.REACT_APP_API_ROOT}/timeline?date=${nextReference.toISOString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((dates: [string, string]) => {
          setFutureDates((s) => [...s, dates])
        })
    }
  };

  const handleFuturePeriodLoaded = (financialPeriod: BRData.FinancialPeriodData) => {
    setFutureBalances((s) => [...s, financialPeriod.balance])
  };

  const handleCurrentPeriodLoaded = (financialPeriod: BRData.FinancialPeriodData) => {
    setCurrentPeriod(financialPeriod);
  }

  const loadPastTimeline = () => {
    const oldest = pastDates[pastDates.length - 1]?.[0] || timePeriod.data?.[0];
    if (oldest) {
      const nextReference = addDays(new Date(oldest), -1);
      fetch(`${process.env.REACT_APP_API_ROOT}/timeline?date=${nextReference.toISOString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((dates: [string, string]) => {
          setPastDates((s) => [...s, dates])
        })
    }
  };

  const pageLoading = balance.loading
    || budgets.loading
    || savings.loading
    || timePeriod.loading;

  if (pageLoading) {
    return <Spinner className='Timeline_PageSpinner' />
  }

  return (
    <main className='Timeline_Page'>
      {currentPeriod && (
        <button className='Timeline_LoadMore' onClick={loadFutureTimeline}>
          <span className="sr-only">Load newer</span> &uarr;
        </button>
      )}

      <div className="Timeline_FutureContainer">
        {futureDates.map(([from, to], i) => {
          const latestPeriod = futureBalances[i - 1] || currentPeriod?.balance;
          const startBalance = latestPeriod.availableBalance + latestPeriod.savingTotal;
          return (
            <FinancialPeriodProvider
              key={to}
              account={account}
              balance={startBalance}
              from={from}
              to={to}
              budgets={budgets.data}
              savings={savings.data}
              removeRemainingBudgetsFromBalance={true}
              onLoading={<SimpleSpinner className='Timeline_PeriodSpinner' />}
              onLoaded={handleFuturePeriodLoaded}
            >
              <TimelinePeriod type='future' />
            </FinancialPeriodProvider>
          )
        })}
      </div>

      <FinancialPeriodProvider
        account={account}
        balance={balance.data.available}
        from={timePeriod.data[0]}
        to={timePeriod.data[1]}
        budgets={budgets.data}
        savings={savings.data}
        includePendingBeforeFromDate={true}
        removeRemainingBudgetsFromBalance={true}
        onLoading={<Spinner className='Timeline_PageSpinner' />}
        onLoaded={handleCurrentPeriodLoaded}
      >
        <TimelinePeriod type='current' />
      </FinancialPeriodProvider>

      {pastDates.map(([from, to]) => {
        return (
          <FinancialPeriodProvider
            key={to}
            account={account}
            balance={balance.data.available}
            from={from}
            to={to}
            budgets={budgets.data}
            savings={savings.data}
            onLoading={<SimpleSpinner className='Timeline_PeriodSpinner' />}
          >
            <TimelinePeriod type='past' />
          </FinancialPeriodProvider>
        )
      })}

      {currentPeriod && (
        <button className='Timeline_LoadMore' onClick={loadPastTimeline}>
          <span className="sr-only">Load older</span> &darr;
        </button>
      )}
    </main>
  );
}

export default Timeline;
