import React from 'react';
import Element, { As } from '../Element';
import BlockListItem from './BlockListItem';
import BlockListTransaction from './BlockListTransaction';

import './BlockList.scss';

export type BlockListProps = {
  className?: string,
  as?: As
};

const BlockList: React.FC<BlockListProps> = ({
  children,
  className = '',
  as = 'ul'
}) => {
  return (
    <Element as={as} className={`BlockList ${className}`}>
      {children}
    </Element>
  )
};

export default BlockList;

export {
  BlockListItem,
  BlockListTransaction
};
