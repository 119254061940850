import React from 'react';
import TransactionAmount, {TransactionAmountProps} from '../TransactionAmount';

import './TransferAmount.scss';

type TransferAmountProps = TransactionAmountProps & {
}

const TransferAmount: React.FC<TransferAmountProps> = ({toggleProps, ...props}) => {
  return (
    <TransactionAmount
      className='TransferAmount'
      toggleProps={{
        onText: 'Withdraw',
        offText: 'Deposit'
      }}
      {...props}
    />
  )
}

export default TransferAmount