import React, { useCallback, useMemo } from 'react';
import formatDate from 'date-fns/format';
import isDateBefore from 'date-fns/isBefore';

import BlockListTransactions from '../../components/BlockList/BlockListTransactions';
import Card from '../../components/Card';
import { useFinancialPeriod, useFinancialPeriodMethods } from '../../providers/FinancialPeriod';
import PeriodRange from '../../components/PeriodRange';
import { withCurrency } from '../../util/currency';
import BlockListHeading from '../../components/BlockList/BlockListHeading';
import Link from '../../components/Link';
import SlideTransitionRouter from '../../components/SlideTransitionRouter';
import BalanceBreakdown from '../sharedRoutes/BalanceBreakdown';
import Transaction from '../sharedRoutes/Transaction';
import PendingTransaction from '../sharedRoutes/PendingTransaction';
import { useNavigate } from '@reach/router';
import Icon from '../../components/Icon';
import BlockList, { BlockListItem } from '../../components/BlockList';
import Budget from '../sharedRoutes/Budget';
import SavingTransaction from '../sharedRoutes/SavingTransaction';
import { useActiveAccount } from '../../hooks/useActiveAccount';
import Box from '../../components/Box';

interface TimelinePeriodProps {
  type: 'current' | 'past' | 'future'
}

const BACK = {
  label: 'Back to timeline',
  href: '/timeline'
};

const BalanceBreakdownStartLabels = {
  current: 'Current bank balance',
  future: 'Starting bank balance',
  past: 'Bank balance'
}

const BalanceBreakdownEndLabels = {
  current: 'Finishing bank balance',
  future: 'Finishing bank balance',
  past: 'Bank balance'
}

const TimelinePeriod: React.FC<TimelinePeriodProps> = ({type}) => {
  const account = useActiveAccount();
  const navigate = useNavigate();
  const {
      data: {transactions, to, from, savings, budgets, balance},
      config: {removeRemainingBudgetsFromBalance}
  } = useFinancialPeriod();
  const { loadTransactions, setRemoveRemainingBudgetsFromBalance } = useFinancialPeriodMethods();

  const handleTransactionSaved = useCallback(() => {
    navigate(`..?account=${account}`);
    loadTransactions();
  }, [navigate, loadTransactions, account]);

  const handleBalanceBreakdownBack = () => {
    setRemoveRemainingBudgetsFromBalance(true);
  };

  const savingTransactions = useMemo(() => {
    return transactions.savingTransactions.filter((transaction) => {
      return !isDateBefore(new Date(transaction.date), new Date(from));
    })
  }, [transactions.savingTransactions, from]);

  const PERIOD_ID = String(to.getTime());

  return (
    <div className='TimelinePeriod'>
      {type === 'past' ? (
        <>
          <div className="Timeline_SectionHeader">
            <h1>{formatDate(new Date(to), 'EEE do MMMM')}</h1>
            <dl className='Timeline_Balances'>
              <dt>Bank balance</dt>
              <dd><b>{withCurrency(transactions.transactions[1]?.running_balance)}</b></dd>
            </dl>
          </div>
          <Box>
            <Card
              as='section'
              className='Timeline_Section'
              header={<PeriodRange from={from} to={to} icon='DateRange' />}
            >
              <h3 className='Card_Heading'>Transactions</h3>
              <BlockListTransactions
                rootPathExtension={PERIOD_ID}
                transactions={transactions.transactions}
              />
            </Card>
          </Box>
        </>
      ) : (
        <>
          <div className="Timeline_SectionHeader">
            <h1>{formatDate(new Date(to), 'EEE do MMMM')}</h1>
            <Link to={`./balance-breakdown__${PERIOD_ID}`} className='Timeline_BalanceLink'>
              <dl className='Timeline_Balances'>
                <dt>Bank balance</dt>
                <dd><b>{withCurrency(balance.availableBalance + balance.savingTotal)}</b></dd>
                {!!savings?.length && (
                  <>
                    <dt>Saving pots</dt>
                    <dd><b>{withCurrency(balance.savingTotal)}</b></dd>
                  </>
                )}
                <dt>Available balance</dt>
                <dd><b>{withCurrency(balance.availableBalance)}</b></dd>
              </dl>
              <Icon name='ChevronRight' />
            </Link>
          </div>
          <Box>
            <Card
              as='section'
              className='Timeline_Section'
              header={<PeriodRange from={from} to={to} icon='DateRange' />}
            >
              <h3 className="Card_Heading">Transactions</h3>

              <BlockListTransactions
                rootPathExtension={PERIOD_ID}
                displayDateTitles={false}
                displayWhenEmpty={null}
                transactions={savingTransactions}
                heading={(
                  <BlockListHeading>
                    Saving pot transfers
                  </BlockListHeading>
                )}
              />

              {removeRemainingBudgetsFromBalance && !!budgets?.length && (
                <BlockList>
                  <BlockListHeading
                    right={withCurrency(balance.accountBalance + balance.pendingTotal - balance.budgetTotal)}
                  >
                    Remaining spending budgets
                  </BlockListHeading>
                  {budgets.map((budget) => {
                    let budgetBalance = budget.budget + balance.budgets[budget._id];
                    budgetBalance = budgetBalance < 0 ? 0 : budgetBalance;
                    return (
                      <BlockListItem
                        key={budget._id}
                        right={<b>{withCurrency(0 - budgetBalance)}</b>}
                        to={`budget__${PERIOD_ID}/${budget._id}`}
                      >
                        <span className="BlockListTransaction_Title">
                          {budget.title}
                        </span>
                      </BlockListItem>
                    )
                  })}
                </BlockList>
              )}

              {type === 'current' ? (
                <>
                  <BlockListTransactions
                    rootPathExtension={PERIOD_ID}
                    displayWhenEmpty={null}
                    transactions={transactions.pendingTransactions}
                  />
                  <BlockListTransactions
                    rootPathExtension={PERIOD_ID}
                    transactions={transactions.transactions}
                  />
                </>
              ) : (
                <BlockListTransactions
                  rootPathExtension={PERIOD_ID}
                  startBalance={balance.accountBalance}
                  transactions={transactions.pendingTransactions}
                />
              )}
            </Card>
          </Box>
        </>
      )}
      <SlideTransitionRouter>
        <BalanceBreakdown
          path={`balance-breakdown__${PERIOD_ID}`}
          back={BACK}
          onBack={handleBalanceBreakdownBack}
          startBalanceLabel={BalanceBreakdownStartLabels[type]}
          endBalanceLabel={BalanceBreakdownEndLabels[type]}
        />
        <Budget
          path={`budget__${PERIOD_ID}/:budgetId/*`}
          back={BACK}
        />
        <Transaction
          path={`transaction__${PERIOD_ID}/:transactionId`}
          back={BACK}
          onSubmit={handleTransactionSaved}
          rootPathExtension={PERIOD_ID}
        />
        <PendingTransaction
          path={`pending-transaction__${PERIOD_ID}/:transactionId`}
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
        <SavingTransaction
          path={`saving-transfer__${PERIOD_ID}/:transactionId`}
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
      </SlideTransitionRouter>
    </div>
  )
}

export default TimelinePeriod;