import './Icon.scss'

type IconSvgProps = React.SVGAttributes<any>

const icons = {
  ChevronLeft: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" fill="currentColor" />
    </svg>,
  ChevronRight: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" fill="currentColor" />
    </svg>,
  ChevronDown: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" fill="currentColor" />
    </svg>,
  ChevronUp: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" fill="currentColor" />
    </svg>,
  Video: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z" fill="currentColor" />
    </svg>,
  Article: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" fill="currentColor" />
    </svg>,
  Interactive: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <g fill="currentColor"><g><path d="M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M20,19H4V5h16V19z" fillRule="evenodd"/><polygon fillRule="evenodd" points="19.41,10.42 17.99,9 14.82,12.17 13.41,10.75 12,12.16 14.82,15"/><rect fillRule="evenodd" height="2" width="5" x="5" y="7"/><rect fillRule="evenodd" height="2" width="5" x="5" y="11"/><rect fillRule="evenodd" height="2" width="5" x="5" y="15"/></g></g>
    </svg>,
  External: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path fill="currentColor" d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z"/>
    </svg>,
  Information: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path fill="currentColor" d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
    </svg>,
  Done: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path fill="currentColor" d="M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M19.79,10.22C19.92,10.79,20,11.39,20,12 c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8c1.58,0,3.04,0.46,4.28,1.25l1.44-1.44C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.19-0.22-2.33-0.6-3.39L19.79,10.22z"/>
    </svg>,
  Delete: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
    </svg>,
  Save: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"/>
    </svg>,
  Savings: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <g><path d="M15,10c0-0.55,0.45-1,1-1s1,0.45,1,1c0,0.55-0.45,1-1,1S15,10.55,15,10z M8,9h5V7H8V9z M22,7.5v6.97l-2.82,0.94L17.5,21 L12,21v-2h-2v2l-5.5,0C4.5,21,2,12.54,2,9.5S4.46,4,7.5,4l5,0c0.91-1.21,2.36-2,4-2C17.33,2,18,2.67,18,3.5 c0,0.21-0.04,0.4-0.12,0.58c-0.14,0.34-0.26,0.73-0.32,1.15l2.27,2.27H22z M20,9.5h-1L15.5,6c0-0.65,0.09-1.29,0.26-1.91 C14.79,4.34,14,5.06,13.67,6L7.5,6C5.57,6,4,7.57,4,9.5c0,1.88,1.22,6.65,2.01,9.5L8,19v-2h6v2l2.01,0l1.55-5.15L20,13.03V9.5z"/></g>
    </svg>,
  DateRange: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
    </svg>,
  Bank: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <path d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z"/>
    </svg>,
  Timeline: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`Icon ${className}`} {...props}>
      <g><g><path d="M23,8c0,1.1-0.9,2-2,2c-0.18,0-0.35-0.02-0.51-0.07l-3.56,3.55C16.98,13.64,17,13.82,17,14c0,1.1-0.9,2-2,2s-2-0.9-2-2 c0-0.18,0.02-0.36,0.07-0.52l-2.55-2.55C10.36,10.98,10.18,11,10,11c-0.18,0-0.36-0.02-0.52-0.07l-4.55,4.56 C4.98,15.65,5,15.82,5,16c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2c0.18,0,0.35,0.02,0.51,0.07l4.56-4.55C8.02,9.36,8,9.18,8,9 c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.18-0.02,0.36-0.07,0.52l2.55,2.55C14.64,12.02,14.82,12,15,12c0.18,0,0.36,0.02,0.52,0.07 l3.55-3.56C19.02,8.35,19,8.18,19,8c0-1.1,0.9-2,2-2S23,6.9,23,8z"/></g></g>
    </svg>,
  Settings: ({className = '', ...props}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={`Icon ${className}`} {...props}>
      <path d="M19.4 44 18.4 37.7Q17.45 37.35 16.4 36.75Q15.35 36.15 14.55 35.5L8.65 38.2L4 30L9.4 26.05Q9.3 25.6 9.275 25.025Q9.25 24.45 9.25 24Q9.25 23.55 9.275 22.975Q9.3 22.4 9.4 21.95L4 18L8.65 9.8L14.55 12.5Q15.35 11.85 16.4 11.25Q17.45 10.65 18.4 10.35L19.4 4H28.6L29.6 10.3Q30.55 10.65 31.625 11.225Q32.7 11.8 33.45 12.5L39.35 9.8L44 18L38.6 21.85Q38.7 22.35 38.725 22.925Q38.75 23.5 38.75 24Q38.75 24.5 38.725 25.05Q38.7 25.6 38.6 26.1L44 30L39.35 38.2L33.45 35.5Q32.65 36.15 31.625 36.775Q30.6 37.4 29.6 37.7L28.6 44ZM24 30.5Q26.7 30.5 28.6 28.6Q30.5 26.7 30.5 24Q30.5 21.3 28.6 19.4Q26.7 17.5 24 17.5Q21.3 17.5 19.4 19.4Q17.5 21.3 17.5 24Q17.5 26.7 19.4 28.6Q21.3 30.5 24 30.5ZM24 27.5Q22.55 27.5 21.525 26.475Q20.5 25.45 20.5 24Q20.5 22.55 21.525 21.525Q22.55 20.5 24 20.5Q25.45 20.5 26.475 21.525Q27.5 22.55 27.5 24Q27.5 25.45 26.475 26.475Q25.45 27.5 24 27.5ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM21.8 41H26.2L26.9 35.4Q28.55 35 30.025 34.15Q31.5 33.3 32.7 32.1L38 34.4L40 30.8L35.3 27.35Q35.5 26.5 35.625 25.675Q35.75 24.85 35.75 24Q35.75 23.15 35.65 22.325Q35.55 21.5 35.3 20.65L40 17.2L38 13.6L32.7 15.9Q31.55 14.6 30.1 13.725Q28.65 12.85 26.9 12.6L26.2 7H21.8L21.1 12.6Q19.4 12.95 17.925 13.8Q16.45 14.65 15.3 15.9L10 13.6L8 17.2L12.7 20.65Q12.5 21.5 12.375 22.325Q12.25 23.15 12.25 24Q12.25 24.85 12.375 25.675Q12.5 26.5 12.7 27.35L8 30.8L10 34.4L15.3 32.1Q16.5 33.3 17.975 34.15Q19.45 35 21.1 35.4Z" fill='currentColor'/>
    </svg>,
}

export type IconKey = keyof typeof icons;
interface Props extends IconSvgProps {
  name: IconKey
}

const Icon: React.FC<Props> = ({name, ...props}) => {
  return icons[name](props);
}

export default Icon
