import React from 'react';
import Element, { As } from '../Element';

import './Box.scss';

const Box: React.FC<{
  className?: string,
  as?: As
}> = ({
  children,
  className = '',
  as = 'div'
}) => {
  return (
    <Element as={as} className={`Box ${className}`}>
      {children}
    </Element>
  )
};

export default Box;
