import { useNavigate } from '@reach/router';
import React, { useCallback, useMemo } from 'react';
import BlockHeading from '../../../components/BlockHeading';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import BlockListTransactions from '../../../components/BlockList/BlockListTransactions';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import PeriodRange from '../../../components/PeriodRange';
import ProgressDial from '../../../components/ProgressDial';
import SlideRoute from '../../../components/SlideRoute';
import SlideTransitionRouter from '../../../components/SlideTransitionRouter';
import Spinner from '../../../components/Spinner';
import { useActiveAccount } from '../../../hooks/useActiveAccount';
import { useFinancialPeriod } from '../../../providers/FinancialPeriod';
import { withCurrency } from '../../../util/currency';
import SavingTransaction from '../SavingTransaction';
import Transaction from '../Transaction';

import './Saving.scss';

type SavingProps = {
  savingId?: string
}

const BACK = {
  label: 'Back to savings',
  href: '../'
};

const Saving: React.FC<BR.SlideRoutePathProps & SavingProps> = ({back, savingId}) => {
  const navigate = useNavigate();
  const account = useActiveAccount();
  const { data: {savings, transactions, balance, from, to} } = useFinancialPeriod();
  const saving = savings?.find(({_id}) => _id === savingId);

  const { savingTransactions } = transactions;

  const handleTransactionSaved = useCallback(() => {
    navigate(`..?account=${account}`);
  }, [navigate, account]);

  if (!saving) {
    return <Spinner />
  }

  const saved = balance.savings[saving._id];

  return (
    <>
      <SlideRoute back={back}>
        <Box as='h2' className='text-center'>{saving.title}</Box>
        
        <Box className='Saving_Columns'>
          <div className="Saving_Column">
            <div className="Saving_Data">
              {!!saving.target && (
                <div className="Saving_DataItem">
                  <p>Target</p>
                  <p>{withCurrency(saving.target)}</p>
                </div>
              )}
              <div className="Saving_DataItem">
                <p>Saved</p>
                <p className='Saving_Saved'>{withCurrency(saved)}</p>
              </div>
            </div>
          </div>
          <div className="Saving_Column">
            {!!saving.target && (
              <ProgressDial
                max={saving.target}
                value={saved}
              />
            )}
          </div>
        </Box>

        <div className='Saving_TransferButtons'>
          <div className='Saving_TransferButton'>
            <Button
              modifiers={['block']}
              as='RouterLink'
              to={`saving-transfer/new?account=${account}&newTAmount=-100&newTSavingAccount=${saving._id}`}
            >+ Deposit</Button>
          </div>
          <div className='Saving_TransferButton'>
            <Button
              modifiers={['block']}
              as='RouterLink'
              to={`saving-transfer/new?account=${account}&newTAmount=100&newTSavingAccount=${saving._id}`}
            >&minus; Withdraw </Button>
          </div>
        </div>

        <BlockList className='Saving_Actions'>
          <BlockListItem to={`/accounts/${saving.bankAccountId}`}>
            Manage savings
          </BlockListItem>
        </BlockList>

        <PeriodRange from={from} to={to} icon='DateRange' />

        <BlockHeading as='h3'>Transfers in/out</BlockHeading>

        <BlockListTransactions
          transactions={savingTransactions}
          transactionProps={{
            reverseValue: true
          }}
        />
      </SlideRoute>

      <SlideTransitionRouter>
        <SavingTransaction
          path='saving-transfer/:transactionId'
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
      </SlideTransitionRouter>
    </>
  )
};

export default Saving;
