import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import './RecurringInput.scss';
import '../SelectInput/SelectInput.scss';

interface Props extends BR.FormControlProps {
}

const RecurringInput: React.FC<Props> = (props) => {
  const {
    register,
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled,
  } = props;
  const { formState } = useFormContext();
  const error = formState.errors[name];

  return (
    <Controller
      name={name}
      rules={validation}
      render={({field}) => {
        const { onChange, value } = field;

        const handlePart1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
          onChange(event.target.value);
        }

        const handlePart2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
          const [part1] = value.split(':');
          onChange(`${part1}:${event.target.value}`);
        }

        const [part1, part2] = (value || '').split(':');

        const requirePart2 = ['1', '2', '3', 'last'].includes(part1);

        const showDescription = ![
          'day',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
          'weekday',
          'working',
        ].includes(part1);

        return (
          <fieldset className={`RecurringInput ${showDescription ? '-with-part-2' : ''}`} id={id}>
            <legend className='RecurringInput_Label'>{label}</legend>
            <label className='SelectInput RecurringInput_Field'>
              <span className='sr-only'></span>
              <select
                className='SelectInput_Control'
                name='part1'
                value={part1 || '#'}
                onChange={handlePart1Change}
                disabled={disabled}
              >
                <option value="#" disabled>Please choose...</option>
                <option value="day">Every day</option>
                <option value="Monday">Every Monday</option>
                <option value="Tuesday">Every Tuesday</option>
                <option value="Wednesday">Every Wednesday</option>
                <option value="Thursday">Every Thursday</option>
                <option value="Friday">Every Friday</option>
                <option value="Saturday">Every Saturday</option>
                <option value="Sunday">Every Sunday</option>
                <option value="weekday">Week day</option>
                <option value="working">Working day</option>
                <option disabled> </option>
                <option value="last">Every last...</option>
                <option value="1">Every 1st...</option>
                <option value="2">Every 2nd...</option>
                <option value="3">Every 3rd...</option>
                <option disabled> </option>
                <option value="4">Every 4th</option>
                <option value="5">Every 5th</option>
                <option value="6">Every 6th</option>
                <option value="7">Every 7th</option>
                <option value="8">Every 8th</option>
                <option value="9">Every 9th</option>
                <option value="10">Every 10th</option>
                <option value="11">Every 11th</option>
                <option value="12">Every 12th</option>
                <option value="13">Every 13th</option>
                <option value="14">Every 14th</option>
                <option value="15">Every 15th</option>
                <option value="16">Every 16th</option>
                <option value="17">Every 17th</option>
                <option value="18">Every 18th</option>
                <option value="19">Every 19th</option>
                <option value="20">Every 20th</option>
                <option value="21">Every 21st</option>
                <option value="22">Every 22nd</option>
                <option value="23">Every 23rd</option>
                <option value="24">Every 24th</option>
                <option value="25">Every 25th</option>
                <option value="26">Every 26th</option>
                <option value="27">Every 27th</option>
                <option value="28">Every 28th</option>
                {/* <option value="29">Every 29th</option>
                <option value="30">Every 30th</option> */}
              </select>
            </label>

            {requirePart2 && (
              <>
                <label className='SelectInput RecurringInput_Field'>
                  <span className='sr-only'></span>
                  <select
                    className='SelectInput_Control'
                    name='part2'
                    value={part2 || '#'}
                    onChange={handlePart2Change}
                    disabled={disabled}
                  >
                    <option value="#" disabled>Please choose...</option>
                    <option value="day">Day</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                    <option value="weekday">Week day</option>
                    <option value="working">Working day</option>
                  </select>
                </label>
              </>
            )}

            {showDescription && (
              <label className='SelectInput RecurringInput_Field'>
                of the month
              </label>
            )}

            {error && (
              <p className='RecurringInput_ErrorMessage'>{error.message}</p>
            )}
          </fieldset>
        )
      }}
    />
  )
}

export default RecurringInput;
