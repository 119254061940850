import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Box from '../Box';
import Button from '../Button';

import './DrawerModal.scss';

type DrawerModalProps = {
  open: boolean;
  title?: string;
  onClose: () => void
}

const DrawerModal: React.FC<DrawerModalProps> = ({children, open, title, onClose}) => {
  return (
    <>
      <CSSTransition
        in={open}
        classNames='-fade'
        timeout={{enter: 300, exit: 300}}
        unmountOnExit
      >
        <div className="DrawerModal_Overlay" />
      </CSSTransition>
      <CSSTransition
        in={open}
        classNames='-slide'
        timeout={{enter: 300, exit: 300}}
        unmountOnExit
      >
        <div className="DrawerModal">
          <Box className="DrawerModal_Header">
            {title && <h3 className='DrawerModal_Title'>{title}</h3>}
            <Button
              modifiers={['link']}
              className='DrawerModal_Close'
              onClick={onClose}
            >
              &times;
            </Button>
          </Box>
          <div className='DrawerModal_Content'>
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

export default DrawerModal;
