import { RouteComponentProps } from '@reach/router';
import React from 'react';
import BlockList, { BlockListItem } from '../../components/BlockList';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Icon from '../../components/Icon';
import SlideTransitionRouter from '../../components/SlideTransitionRouter';
import { useTruelayer } from '../../hooks/useTrueLayer';
import { useAccounts } from '../../providers/Accounts';
import Account from './Account';

import './Accounts.scss';

const Accounts: React.FC<BR.RoutePathProps & RouteComponentProps> = () => {
  const accounts = useAccounts();

  const { startBankConnection } = useTruelayer();

  return (
    <>
      <div className="Accounts_Page">
        <Box as='h1'>Accounts</Box>
        <Card>
          <BlockList>
          {accounts?.map((account) => {
            const { number, sort_code } = account.account_number;
            return (
              <BlockListItem
                key={account.account_id}
                to={account.account_id}
                className='Accounts_Account'
                left={<img src={account.provider?.logo_uri} alt='' />}
              >
                <h4>{account.display_name}</h4>
                <p>{number} | {sort_code}</p>
              </BlockListItem>
            )
          })}
          </BlockList>
        </Card>
        <Box>
          <Button
            modifiers={['block']}
            className='Accounts_ConnectAccount'
            onClick={startBankConnection}
          >
            Connect bank account
            <Icon name="External" />
          </Button>
        </Box>
      </div>
      
      <SlideTransitionRouter>
        <Account
          path=':accountId/*'
          back={{
            label: 'Back to accounts',
            href: '../'
          }}
        />
      </SlideTransitionRouter>
    </>
  );
}

export default Accounts;
