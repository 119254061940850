export const CURRENCY = 'GBP';

export const CURRENCY_REGEX =
  /(?=.*?\d)^£?(([1-9]\d{0,2}(,\d{3s})*)|\d+)?(\.\d{1,2})?$/;

export const SYMBOLS = {
  GBP: '£'
};

export const intToCurrency = (val: number = 0): string =>
  val ? (val / 100).toFixed(2) : '0';

export const currencyToInt = (str: string): number => {
  if (CURRENCY_REGEX.test(str)) {
    const hasDecimal = str.includes('.');
    const formatted = str.replace(/[,£]/g, '');
    if (hasDecimal) {
      const decimal = formatted.split('.')[1];
      if (decimal.length > 1) {
        // only remove the decimal
        // no need to turn to pence
        return parseInt(formatted.replace('.', ''));
      }
      return parseInt(formatted.replace('.', '') + '0');
    }
    // otherwise we need to
    // turn into pence
    return parseFloat(formatted) * 100;
  }
  // TODO:
  // do something better, try to work out
  // what the user was supposed to put in there
  // remove characters other than digits and .
  // and try again, maybe recursively call this method
  return 0;
};

export const withCurrency = (amount?: number | null, currency: keyof typeof SYMBOLS = CURRENCY) =>
  SYMBOLS[currency] + intToCurrency(amount || 0);