import React from 'react'
import BlockListItem, { BlockListItemProps } from './BlockListItem'

const BlockListHeading: React.FC<BlockListItemProps> = ({children, ...props}) => {
  return (
    <BlockListItem
      {...props}
      hasChevron={false}
      modifiers={['small', 'title']}
    >
      {children}
    </BlockListItem>
  )
}

export default BlockListHeading