import React from 'react';
import BlockListItem, { BlockListItemProps } from './BlockListItem';
import { withCurrency } from '../../util/currency';

import './BlockListSaving.scss';
import ProgressDial from '../ProgressDial';
import { useFinancialPeriod } from '../../providers/FinancialPeriod';

export type BlockListSavingProps = BlockListItemProps & {
  saving: BRData.Savings
  to?: string
}

const BlockListSaving: React.FC<BlockListSavingProps> = ({
  className = '',
  saving,
  to,
  children,
  ...props
}) => {
  const { data: financialPeriod } = useFinancialPeriod();
  const { balance } = financialPeriod;
  const value = balance.savings[saving._id];
  return (
    <BlockListItem
      className={`BlockListSaving ${className}`}
      to={to}
      right={!!saving.target && (
        <ProgressDial
          className='BlockListSaving_Progress'
          value={value}
          max={saving.target}
        />
      )}
      {...props}
    >
      <span className='BlockListSaving_Title'>{saving.title}</span>
      <div className="BlockListSaving_Stats">
        <span className='BlockListSaving_Stat'>{withCurrency(value)}</span>
        {!!saving.target && (
          <span className='BlockListSaving_Stat'>({withCurrency(saving.target)})</span>
        )}
      </div>
      {children}
    </BlockListItem>
  )
};

export default BlockListSaving;
