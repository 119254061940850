import React from 'react';
import BlockListItem, { BlockListItemProps } from './BlockListItem';
import { withCurrency } from '../../util/currency';

import './BlockListBudget.scss';
import ProgressDial from '../ProgressDial';
import { useFinancialPeriod } from '../../providers/FinancialPeriod';

export type BlockListBudgetProps = BlockListItemProps & {
  budget: BRData.Budget
  to?: string
}

const BlockListBudget: React.FC<BlockListBudgetProps> = ({
  className = '',
  budget,
  to,
  children,
  ...props
}) => {
  const { data: financialPeriod } = useFinancialPeriod();
  const { balance } = financialPeriod;
  const value = balance.budgets[budget._id];
  return (
    <BlockListItem
      className={`BlockListBudget ${className}`}
      to={to}
      right={(
        <ProgressDial
          className='BlockListBudget_Progress'
          value={value}
          max={budget.budget}
          negativeValue={true}
          moreIsBad={true}
        />
      )}
      {...props}
    >
      <span className='BlockListBudget_Title'>{budget.title}</span>
      <div className="BlockListBudget_Stats">
        <span className='BlockListBudget_Stat'>{withCurrency((value > 0) ? 0 : (0 - value))}</span>
        <span className='BlockListBudget_Stat'>({withCurrency(budget.budget)})</span>
      </div>
      {children}
    </BlockListItem>
  )
};

export default BlockListBudget;
