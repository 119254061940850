import React, { ReactNode } from 'react';
import Element, { As } from '../Element';
import Icon from '../Icon';

import './BlockListItem.scss';

export type BlockListItemProps = {
  className?: string,
  as?: As,
  left?: ReactNode,
  right?: ReactNode,
  leftIcon?: ReactNode,
  rightIcon?: ReactNode,
  hasChevron?: boolean,
  to?: string,
  linkAs?: 'Link' | 'RouterLink';
  modifiers?: string[],
  onClick?: (e: React.MouseEvent) => void
};

const BlockListItem: React.FC<BlockListItemProps> = ({
  children,
  className = '',
  as = 'li',
  left,
  right,
  rightIcon,
  leftIcon,
  hasChevron = true,
  to,
  linkAs = 'Link',
  modifiers = [],
  onClick
}) => {
  return (
    <Element
      as={as}
      onClick={onClick}
      type={as === 'button' ? 'button' : undefined}
      className={`BlockListItem ${className} ${modifiers.map(m => `-${m}`).join(' ')}`}
    >
      <Element
        as={!to ? 'span' : linkAs}
        to={to}
        className={`BlockListItem_Inner`}
      >
        {leftIcon && <span className="BlockListItem_LeftIcon">{leftIcon}</span>}
        {left && <span className="BlockListItem_Left">{left}</span>}
        <span className="BlockListItem_Main">
          {children}
        </span>
        {right && <span className="BlockListItem_Right">{right}</span>}
        {rightIcon && <span className="BlockListItem_RightIcon">{rightIcon}</span>}
        {hasChevron && (
          <span className="BlockListItem_Chevron">
            <Icon name='ChevronRight' />
          </span>
        )}
      </Element>
    </Element>
  )
};

export default BlockListItem;
