import React from 'react';
import formatDate from 'date-fns/format';
import BlockList, { BlockListProps } from '.';
import BlockListItem from './BlockListItem';
import { withCurrency } from '../../util/currency';
import BlockListTransaction, { BlockListTransactionProps } from './BlockListTransaction';
import BlockListHeading from './BlockListHeading';

const getTransactionPath = (transaction: BRData.Transaction, rootPathExtension?: string) => {
  const _id = transaction.transaction_id || transaction._id;
  const type = transaction.type === 'saving'
    ? 'saving-transfer'
    : !transaction.transaction_id
      ? 'pending-transaction'
      : 'transaction';
  const pathExt = rootPathExtension
    ? `__${rootPathExtension}`
    : '';

  return `${type}${pathExt}/${_id}`;
}

const BlockListTransactions: React.FC<BlockListProps & {
  transactions: BRData.Transaction[],
  startBalance?: number,
  displayWhenEmpty?: React.ReactNode,
  displayDateTitles?: boolean,
  heading?: React.ReactNode,
  rootPathExtension?: string
  transactionProps?: Partial<BlockListTransactionProps>
}> = ({
  transactions,
  startBalance,
  displayWhenEmpty = (
    <BlockListItem hasChevron={false}>
      No transactions to display...
    </BlockListItem>
  ),
  displayDateTitles = true,
  heading,
  rootPathExtension,
  transactionProps
}) => {
  const useStartBalance = typeof startBalance === 'number';
  const transactionsToUse = [...transactions];
  if (useStartBalance) {
    let runningBalance = startBalance;
    for (let i = transactionsToUse.length - 1; i >= 0; i--) {
      const transaction = transactionsToUse[i];
      if (Array.isArray(transaction)) {
        transaction[1] = runningBalance;
      } else {
        runningBalance += transaction.amount;
      }
    }
  }
  return (
    <BlockList className='BlockListTransactions'>
      {(!transactionsToUse.length && displayWhenEmpty) ? displayWhenEmpty : null}
      {!!transactionsToUse?.length && heading}
      {transactionsToUse.map((transaction) => {
        if (Array.isArray(transaction)) {
          if (!displayDateTitles) {
            return null;
          }
          const [date, running_balance] = transaction;
          return (
            <BlockListHeading
              key={date}
              right={running_balance ? withCurrency(running_balance) : undefined}
            >
              {formatDate(new Date(date), 'EEE do MMM')}
            </BlockListHeading>
          )
        }
        const _id = transaction.transaction_id || transaction._id;
        return (
          <BlockListTransaction
            key={_id}
            transaction={transaction}
            to={getTransactionPath(transaction, rootPathExtension)}
            as='Link'
            {...transactionProps}
          />
        )
      })}
    </BlockList>
  )
}

export default BlockListTransactions;