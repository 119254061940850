import React, { useContext } from 'react';

type AccountProviderProps = {
  account?: TLData.Account,
  savings?: BRData.Savings[],
  budgets?: BRData.Budget[]
};

const AccountContext = React.createContext<AccountProviderProps>({});

const AccountProvider: React.FC<AccountProviderProps> = ({account, savings, budgets, children}) => {
  return (
    <AccountContext.Provider value={{account, savings, budgets}}>
      {children}
    </AccountContext.Provider>
  );
}

export const useAccount = () => {
  return useContext(AccountContext);
}

export default AccountProvider;
