import React from 'react';
import Element, { As } from '../Element';
import { SimpleSpinner } from '../Spinner';

import './Button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<any> {
  className?: string,
  as?: As,
  to?: string
  href?: string
  modifiers?: string[]
  loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  as = 'button',
  modifiers = [],
  loading,
  ...props
}) => {
  const modifierClasses = modifiers.map((mod) => ` -${mod}`);
  return (
    <Element as={as} className={`Button ${modifierClasses} ${className}`} {...props}>
      {loading ? <SimpleSpinner className='Button_Spinner' /> : children}
    </Element>
  )
};

export default Button;