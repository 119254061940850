import React from 'react';

import './ProgressDial.scss';

type ProgressDialProps = {
  value: number
  max: number
  negativeValue?: boolean
  moreIsBad?: boolean
  className?: string
}

const ProgressDial: React.FC<ProgressDialProps> = ({value, max, negativeValue, moreIsBad = false, className = ''}) => {
  const negatedValue = negativeValue ? 0 - value : value;
  const percentageComplete = negatedValue / max * 100;
  const percentage = percentageComplete > 100
    ? 100
    : percentageComplete < 0
      ? 0
      : percentageComplete;

  const pieStyle = percentage <= 50
    ? {}
    : { clip: 'rect(auto, auto, auto, auto)' };

  const borderColor = moreIsBad
    ? (percentage <= 50
      ? '#61c16b'
      : percentage <= 75
        ? '#ff872a'
        : '#ff6347')
    : (percentage >= 75
      ? '#61c16b'
      : percentage >= 50
        ? '#ff872a'
        : '#ff6347');

  const leftStyle = {
    borderColor,
    transform: `rotate(${percentage * 3.6}deg)`
  }

  const rightStyle = percentage <= 50
    ? { display: 'none' }
    : { borderColor, transform: 'rotate(180deg)' }

  return (
    <div className={`ProgressDial ${className}`}>
      <span className='ProgressDial_Label'>
        {percentageComplete < 0 ? 0 : Math.ceil(percentageComplete)}<small>%</small>
      </span>
      <div className='ProgressDial_Pie'
        style={pieStyle}
      >
        <div className='ProgressDial_HalfCircle -left'
          style={leftStyle}
        />
        <div className='ProgressDial_HalfCircle -right'
          style={rightStyle}
        />
      </div>
    </div>
  )
}

export default ProgressDial;
