import React, { useEffect, useMemo } from 'react';
import { useApi } from '../../api/useApi';
import Spinner from '../../components/Spinner';
import { useActiveAccount } from '../../hooks/useActiveAccount';

import { RouteComponentProps } from '@reach/router';
import FinancialPeriodProvider from '../../providers/FinancialPeriod';
import BalanceView from './Balance';

const Balance: React.FC<BR.RoutePathProps & RouteComponentProps> = () => {
  const account = useActiveAccount();
  
  const date = useMemo(() => new Date(), []);
  const [ timePeriod, loadTimePeriod ] = useApi<[string, string]>(`timeline?date=${date}`, true);

  const [ balance, loadBalance ] = useApi<TLData.AccountBalance>(`balance?account=${account}`, true);
  const [ budgets, loadBudgets ] = useApi<BRData.Budget[]>(`budgets?account=${account}`, true);
  const [ savings, loadSavings ] = useApi<BRData.Savings[]>(`savings?account=${account}`, true);

  useEffect(() => {
    loadTimePeriod();
    loadBalance();
    loadBudgets();
    loadSavings();
  }, [loadTimePeriod, loadBalance, loadBudgets, loadSavings]);

  const loading = balance.loading
    || budgets.loading
    || savings.loading
    || timePeriod.loading;

  if (loading) {
    return <Spinner className='Balance_PageSpinner' />
  }

  return (
    <FinancialPeriodProvider
      account={account}
      balance={balance.data.available}
      from={timePeriod.data[0]}
      to={timePeriod.data[1]}
      budgets={budgets.data}
      savings={savings.data}
      includePendingBeforeFromDate={true}
      onLoading={<Spinner className='Balance_PageSpinner' />}
    >
      <BalanceView />
    </FinancialPeriodProvider>
  );
}

export default Balance;
