import React, { useContext } from 'react';

const AccountsContext = React.createContext<TLData.Account[]>([]);

type AccountsProviderProps = {
  accounts: TLData.Account[]
};

const AccountsProvider: React.FC<AccountsProviderProps> = ({accounts, children}) => {
  return (
    <AccountsContext.Provider value={accounts}>
      {children}
    </AccountsContext.Provider>
  );
}

export const useAccounts = () => {
  return useContext(AccountsContext);
}

export default AccountsProvider;
