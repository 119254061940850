import React from 'react';
import { useFormContext } from 'react-hook-form';

import './Input.scss';

interface Props extends BR.FormControlProps {
  type?: 'text' | 'email' | 'tel'
}

const Input: React.FC<Props> = (props) => {
  const {
    register,
    type = 'text',
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled
  } = props;
  const { formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <>
      <label className='Input'>
        <span className='Input_Label'>{label}</span>
        <input
          className='Input_Control'
          type={type}
          name={name}
          id={id}
          {...register(name, {
            onChange,
            onBlur,
            onFocus,
            disabled,
            ...validation
          })}
        />
      </label>
      {error && (
        <p className='Input_ErrorMessage'>{error.message}</p>
      )}
    </>
  )
}

export default Input;
