import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';

type AuthContextState = {
  loading: boolean
  error: any
  data: string
}

const AuthContext = React.createContext<AuthContextState>({
  error: null,
  loading: false,
  data: null as unknown as string,
});

const AuthProvider: React.FC = ({children}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState<AuthContextState>({
    error: null,
    loading: true,
    data: null as unknown as string,
  });
  useEffect(() => {
    setState((s) => ({
      ...s,
      error: null,
      loading: true,
    }));
    try {
      // delaying call to prevent redirect loop in safari
      // after login (token doesn't seem to be present yet)
      setTimeout(async () => {
        const token = await getAccessTokenSilently();
        setState({
          error: null,
          loading: false,
          data: token,
        });
      }, 500);
    } catch(e) {
      setState((s) => ({
        ...s,
        error: e,
        loading: false,
      }));
    };
  }, [getAccessTokenSilently]);

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAccessToken = () => {
  return useContext(AuthContext);
}

export default AuthProvider;
