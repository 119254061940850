import React from 'react';
import Button from '../../components/Button';
import { useTruelayer } from '../../hooks/useTrueLayer';

import './ConnectBank.scss';

const ConnectBank: React.FC<BR.RoutePathProps> = () => {
  const {startBankConnection} = useTruelayer();
  return (
    <div className="ConnectBank">
      <h1 className='ConnectBank_Title'>Connect your bank account to get started</h1>
      <Button onClick={startBankConnection}>Connect bank</Button>
    </div>
  )
}

export default ConnectBank