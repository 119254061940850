import React, { useCallback } from 'react';

import './CheckboxToggleInput.scss';

export type CheckboxToggleInputProps = {
  value: any
  checked: boolean
  onChange: ({value, checked}: {value: any, checked: boolean}) => void
  className?: string
  disabled?: boolean
  onText?: string
  offText?: string
}

const CheckboxToggleInput: React.FC<CheckboxToggleInputProps> = ({
  value,
  checked,
  onChange,
  className = '',
  disabled,
  onText = 'YES',
  offText = 'NO'
}) => {
  const handleChange = useCallback(() => {
    onChange({value, checked: !checked});
  }, [onChange, value, checked]);
  return (
    <label className={`CheckboxToggleInput ${className}${checked ? ' -checked' : ''}${disabled ? ' -disabled' : ''}`}>
      <span className="sr-only">
        {value}
        <input type='checkbox' value={value} checked={checked} onChange={handleChange} disabled={disabled} />
      </span>
      <div className='CheckboxToggleInput_Toggle'>
        {checked ? onText : offText}
      </div>
    </label>
  );
};

export default CheckboxToggleInput;