import React, { useEffect, useState, useRef } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Redirect, Router, Link as RouterLink, RouteComponentProps } from '@reach/router';

import Balance from './views/Balance';
import Timeline from './views/Timeline';
import Accounts from './views/Accounts';
import ConnectBank from './views/ConnectBank';

import Link from './components/Link';
import Spinner from './components/Spinner';
import Icon from './components/Icon';

import { useApi } from './api/useApi';
import { useActiveAccount } from './hooks/useActiveAccount';

import './App.scss';
import Button from './components/Button';
import ConnectBankCallback from './views/ConnectBank/ConnectBankCallback';
import Box from './components/Box';
import AccountsProvider, { useAccounts } from './providers/Accounts';

export const NoAuthState: React.FC<BR.RoutePathProps> = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box className="App_NoAuthState">
      <h1>Please sign in or register to use the app</h1>
      <Button onClick={loginWithRedirect}>Sign in / Register</Button>
    </Box>
  )
}

const Navigation: React.FC = () => {
  return (
    <nav className='App_Nav'>
      <Link to={`/balance`}>
        <Icon name='Savings' />
        Balance
      </Link>
      <Link to={`/timeline`}>
        <Icon name='Timeline' />
        Timeline
      </Link>
      <RouterLink to={`/accounts`}>
        <Icon name='Bank' />
        Accounts
      </RouterLink>
    </nav>
  );
}

const Header: React.FC<{
  activeAccount: string | undefined,
  onAccountChange: (accountId: string) => void,
  displayAccountSelect: boolean
}> = ({
  activeAccount,
  onAccountChange,
  displayAccountSelect
}) => {
  const { logout } = useAuth0();
  const accounts = useAccounts();
  const activeAccountData = accounts?.find((a) => a.account_id === activeAccount);

  const selectAccountRef = useRef<any>(null);

  const [selectAccountOpen, setSelectAccountOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleAccountOpen = () => {
    setSelectAccountOpen(true);
    // setSettingsOpen(false);
  };

  const handleSettingOpen = () => {
    // setSelectAccountOpen(false);
    setSettingsOpen(true);
  };

  const handleAccountSelect = (account: TLData.Account) => {
    onAccountChange(account.account_id);
  }

  useEffect(() => {
    const scrollHandler = () => {
      setSelectAccountOpen(false);
      setSettingsOpen(false);
    }
    const clickHandler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const hasClickedSettingsControl = target.classList.contains('App_UserMenuLink')
        || !!target.closest('.App_UserMenuLink');
      if (!hasClickedSettingsControl) {
        setSettingsOpen(false);
      }
      const hasClickedAccountControl = target.classList.contains('App_HeaderAccountControls')
        || !!target.closest('.App_HeaderAccountControls');
        if (!hasClickedAccountControl) {
          setSelectAccountOpen(false);
        }
    }
    window.addEventListener('scroll', scrollHandler);
    document.body.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      document.body.removeEventListener('click', clickHandler);
    }
  }, []);

  return (
    <header className='App_Header'>
      <button
        onClick={handleSettingOpen}
        className='App_UserMenuLink'
        ref={selectAccountRef.current}
      >
        <Icon name='Settings' />
      </button>
      {settingsOpen && (
        <div className="App_HeaderMenu -left">
          <Link to='/settings' onClick={() => setSettingsOpen(false)}>Settings</Link>
          <Button modifiers={['link']}
            onClick={() => logout()}
          >Logout</Button>
        </div>
      )}
      {!!accounts?.length && displayAccountSelect && (
        <>
          <Button
            modifiers={['link']}
            className="App_HeaderAccountControls"
            onClick={handleAccountOpen}
          >
            {activeAccountData?.provider ? (
              <>
                <img className='App_ActiveAccount' src={activeAccountData.provider.logo_uri} alt={activeAccountData.display_name} />
                <h5>{activeAccountData.display_name}</h5>
                <Icon name='ChevronDown' />
              </>
            ) : (
              <>
                <h5>Choose account</h5>
                <Icon name='ChevronDown' />
              </>
            )}
          </Button>
          {selectAccountOpen && (
            <Box className='App_HeaderMenu -right'>
              {accounts.map((account) => {
                return (
                  <Button
                    key={account.account_id}
                    modifiers={['link']}
                    onClick={() => handleAccountSelect(account)}
                  >
                    <img
                      src={account.provider.logo_uri}
                      alt={account.display_name}
                    />
                    <span>{account.display_name}</span>
                  </Button>
                )
              })}
            </Box>
          )}
        </>
      )}
    </header>
  )
}

const PageLoader = () => {
  return (
    <div className="App -loading">
      <Spinner className='App_PageSpinner' />
    </div>
  );
}

const DISPLAY_ACCOUNT_SELECT_PATHS = [
  'balance',
  'timeline'
];

const App: React.FC<BR.RoutePathProps & RouteComponentProps> = ({location, navigate, ...props}) => {
  const [ accounts, loadAccounts ] = useApi<TLData.Account[]>('accounts', true);
  const activeAccount = useActiveAccount();
  const requireActiveAccount = DISPLAY_ACCOUNT_SELECT_PATHS
    .includes(location?.pathname?.slice(1).split('/')[0] as string);

  useEffect(() => {
    loadAccounts();
  }, [loadAccounts]);

  /**
   * Ensure active account is selected
   */
  useEffect(() => {
    if (!activeAccount && requireActiveAccount && accounts?.data?.length) {
      navigate?.(`${location?.pathname}?account=${accounts.data[0].account_id}`);
    }
  }, [
    accounts.data,
    activeAccount,
    navigate,
    requireActiveAccount,
    location?.pathname
  ]);

  /**
   * Redirect to connect-bank if no accounts
   */
  useEffect(() => {
    if (accounts?.error?.message === 'No connections') {
      navigate?.('/connect-bank');
    }
  }, [accounts.error, navigate]);

  if (accounts.loading || (requireActiveAccount && !activeAccount)) {
    return (
      <PageLoader />
    );
  }

  return (
    <div className='App'>
      <AccountsProvider accounts={accounts.data}>
        <div className="App_Scroller">
          <Header
            activeAccount={activeAccount}
            onAccountChange={(accountId) => navigate?.(`?account=${accountId}`)}
            displayAccountSelect={requireActiveAccount}
          />
          <Router className='App_Main'>
            <Balance path="balance/*" />
            <Timeline path="timeline/*" />
            <Accounts path="accounts/*" />
            <ConnectBank path="connect-bank" />
            <ConnectBankCallback path="confirm-truelayer" />
            <Redirect from='/' to='balance' />
          </Router>
        </div>
        <Navigation />
      </AccountsProvider>
      <div id='route-portal' />
    </div>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <PageLoader />,
});
