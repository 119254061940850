import React from 'react';
import Element, { As } from '../Element';

import './BlockHeading.scss';

const BlockHeading: React.FC<{
  className?: string,
  as?: As
}> = ({
  children,
  className = '',
  as = 'h2'
}) => {
  return (
    <Element as={as} className={`BlockHeading ${className}`}>
      {children}
    </Element>
  )
};

export default BlockHeading;