import React from 'react';
import { createPortal } from 'react-dom';
import { Router, Location } from '@reach/router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import './SlideTransitionRouter.scss';

// const SlideTransitionPortal: React.FC = ({children}) => {
//   const portalElement = document.getElementById('route-portal') as Element;
//   return createPortal(children, portalElement);
// };

const SlideTransitionRouter: React.FC = ({children}) => {
  return (
    <Location>
      {({ location }) => (
          <SwitchTransition mode='in-out'>
            <CSSTransition key={location.key} classNames='-slide' timeout={{enter: 300, exit: 300}}>
              <Router location={location} className='SlideTransitionRouter_Router'>
                {children}
              </Router>
            </CSSTransition>
          </SwitchTransition>
        )}
    </Location>
  );
};

export default SlideTransitionRouter;
