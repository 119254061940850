import React from 'react';
import BlockHeading from '../../../components/BlockHeading';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import BlockListHeading from '../../../components/BlockList/BlockListHeading';
import PeriodRange from '../../../components/PeriodRange';
import SlideRoute from '../../../components/SlideRoute';
import Toggle from '../../../components/Toggle';
import { useFinancialPeriod, useFinancialPeriodMethods } from '../../../providers/FinancialPeriod';
import { withCurrency } from '../../../util/currency';

import './BalanceBreakdown.scss';

type BalanceBreakdownProps = {
  startBalanceLabel?: string
  endBalanceLabel?: string
}

const BalanceBreakdown: React.FC<BR.SlideRoutePathProps & BalanceBreakdownProps> = ({
  back,
  onBack,
  startBalanceLabel = 'Bank balance',
  endBalanceLabel = 'Projected bank balance'
}) => {
  const { data, config } = useFinancialPeriod();
  const { setRemoveRemainingBudgetsFromBalance } = useFinancialPeriodMethods();
  const { from, to, budgets, savings, balance } = data;

  const handleRemainingBudgetToggle = () => {
    setRemoveRemainingBudgetsFromBalance(!config.removeRemainingBudgetsFromBalance);
  };

  return (
    <SlideRoute back={back} onBack={onBack}>
      <PeriodRange className='BalanceBreakdown_PeriodRange' from={from} to={to}  />
      <BlockHeading>Balance breakdown</BlockHeading>
      
      <BlockList className=''>
        {/* <BlockListHeading>
          Bank balance
        </BlockListHeading> */}
        <BlockListItem
          hasChevron={false}
          right={<b>{withCurrency(balance.accountBalance)}</b>}
        >
          <b>{startBalanceLabel}</b>
        </BlockListItem>
      </BlockList>

      <BlockList className='BalanceBreakdown_Section -pending'>
        <BlockListHeading>
          Pending transactions
        </BlockListHeading>
        <BlockListItem
          hasChevron={false}
          right={
            balance.pendingTotal > 0
              ? `+ ${withCurrency(balance.pendingTotal)}`
              : `- ${withCurrency(0 - balance.pendingTotal)}`
          }
        >
          Pending transactions
        </BlockListItem>
      </BlockList>

      {!!budgets?.length && (
        <BlockList className={`BalanceBreakdown_Section -budgets ${!config.removeRemainingBudgetsFromBalance ? ' -disabled' : ''}`}>
          <BlockListHeading
            right={(
              <Toggle
                modifiers={['small']}
                value='removeRemainingBudgetsFromBalance'
                checked={!!config.removeRemainingBudgetsFromBalance}
                onChange={handleRemainingBudgetToggle}
              />
            )}
          >
            Remaining spending budgets
          </BlockListHeading>
          {budgets.map((budget) => {
            let budgetBalance = budget.budget + balance.budgets[budget._id];
            budgetBalance = budgetBalance < 0 ? 0 : budgetBalance;
            return (
              <BlockListItem
                className='BalanceBreakdown_BudgetCategory'
                key={budget._id}
                hasChevron={false}
                right={`- ${withCurrency(budgetBalance)}`}
              >
                {budget.title}
              </BlockListItem>
            )
          })}
        </BlockList>
      )}

      <BlockList className=''>
        {/* <BlockListHeading>
          Bank balance
        </BlockListHeading> */}
        <BlockListItem
          hasChevron={false}
          right={<b>{withCurrency(balance.availableBalance + balance.savingTotal)}</b>}
        >
          <b>{endBalanceLabel}</b>
        </BlockListItem>
      </BlockList>

      {!!savings?.length && (
        <BlockList className='BalanceBreakdown_Section -saving'>
          <BlockListHeading>
            Saving pots
          </BlockListHeading>
          {savings.map((savingAccount) => {
            const savingBalance = balance.savings[savingAccount._id];
            const right = savingBalance > 0
              ? `- ${withCurrency(savingBalance)}`
              : `+ ${withCurrency(savingBalance)}`
            return (
              <BlockListItem
                key={savingAccount._id}
                hasChevron={false}
                right={right}
              >
                {savingAccount.title}
              </BlockListItem>
            )
          })}
        </BlockList>
      )}

      <BlockList className=''>
        {/* <BlockListHeading>
          Total
        </BlockListHeading> */}
        <BlockListItem
          hasChevron={false}
          right={<b>{withCurrency(balance.availableBalance)}</b>}
        >
          <b>{config.removeRemainingBudgetsFromBalance ? 'Remaining' : 'Available balance'}</b>
        </BlockListItem>
      </BlockList>
    </SlideRoute>
  )
};

export default BalanceBreakdown;
