import React from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from '../../Icon';

import './SelectInput.scss';

interface Props extends BR.FormControlProps {
}

const SelectInput: React.FC<Props> = (props) => {
  const {
    register,
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled,
    children
  } = props;
  const { formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <>
      <label className='SelectInput'>
        <span className='SelectInput_Label'>{label}</span>
        <select
          className='SelectInput_Control'
          name={name}
          id={id}
          {...register(name, {
            onChange,
            onBlur,
            onFocus,
            disabled,
            ...validation
          })}
        >
          {children}
        </select>
        <Icon name='ChevronDown' />
      </label>
      {error && (
        <p className='SelectInput_ErrorMessage'>{error.message}</p>
      )}
    </>
  )
}

export default SelectInput;
