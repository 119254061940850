export const parseSearch = (searchString: string) => {
  const search = searchString.replace(/^\?/, '');
  const parts = search.split('&');
  return parts.reduce<{[key: string]: string}>((acc, val) => {
    const [key, value] = val.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
};

export const formatSearch = (search: {[key: string]: string}) => {
  return Object.keys(search).reduce((acc, key) => {
    let value = search[key];
    const symbol = acc.length ? '&' : '?';
    if (value) {
      acc += `${symbol}${key}=${encodeURIComponent(value)}`
    }
    return acc;
  }, '');
};
