import React, { ChangeEvent, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { currencyToInt, intToCurrency, withCurrency } from '../../../util/currency';

import './AmountInput.scss';

export interface AmountInputProps extends BR.FormControlProps {
}

const AmountInput: React.FC<AmountInputProps> = (props) => {
  const {
    register,
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled,
    className = '',
  } = props;
  const { watch, setValue, formState } = useFormContext();
  const error = formState.errors[name];

  const value = watch(name);
  const [faceValue, setFaceValue] = useState<string>(withCurrency(value));

  const handleInputFocus = useCallback((e) => {
    setFaceValue(intToCurrency(value));
    onFocus?.(e);
    e.target.select?.();
  }, [value, onFocus]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFaceValue(e.target.value);
    setValue(name, currencyToInt(e.target.value), { shouldValidate: true });
    onChange?.(e);
  }, [name, setValue, onChange]);

  const handleInputBlur = useCallback((e) => {
    setFaceValue(withCurrency(value));
    onBlur?.(e);
  }, [value, onBlur]);
  
  return (
    <>
      <div className={`AmountInput ${className}`}>
        <label className='AmountInput_Field'>
          <span className='AmountInput_Label'>{label}</span>
          <input type="hidden"
            name={name}
            id={id}
            {...register(name, {
              valueAsNumber: true,
              ...validation
            })}
          />
          <input
            className='AmountInput_Control'
            inputMode='numeric'
            type='text'
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            value={faceValue}
            disabled={disabled}
          />
        </label>
      </div>
      {error && (
        <p className='AmountInput_ErrorMessage'>{error.message}</p>
      )}
    </>
  )
}

export default AmountInput;
