import React, { useCallback, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import BlockList from '../BlockList';
import BlockListTransaction, { BlockListTransactionProps } from '../BlockList/BlockListTransaction';
import Icon from '../Icon';
import DrawerModal from '../DrawerModal';
import BlockListTransactions from '../BlockList/BlockListTransactions';

export type TransactionReferenceValue = Pick<BRData.Transaction, 'title' |'subtitle' | 'amount'>;

type TransactionReferenceProps = Omit<BlockListTransactionProps, 'transaction'> & {
  value: TransactionReferenceValue
  transactions?: BRData.Transaction[]
  onChange?: (value: TransactionReferenceValue) => void
};

const TransactionReference: React.FC<TransactionReferenceProps> = ({
  value,
  transactions,
  onChange,
  children,
  ...props
}) => {
  const [selectionOpen, setSelectionOpen] = useState(false);
  const handleSelection = useCallback((transaction: BRData.Transaction) => {
    onChange?.(transaction);
    setSelectionOpen(false);
  }, [onChange]);

  return (
    <>
      <BlockList>
        <BlockListTransaction
          transaction={value as BRData.Transaction}
          as='button'
          modifiers={['feature']}
          hasChevron={false}
          rightIcon={onChange ? <Icon name='ChevronDown' /> : undefined}
          onClick={() => setSelectionOpen(true)}
          {...props}
        >
          {!value.title && !value.subtitle ? 'Choose a transaction' : ''}
        </BlockListTransaction>
      </BlockList>
      {!!transactions?.length && (
        <DrawerModal open={selectionOpen} title='Choose a transaction' onClose={() => setSelectionOpen(false)}>
          <BlockListTransactions
            transactions={transactions}
            transactionProps={{
              onClick: handleSelection,
              hasChevron: false,
              as: 'button',
              to: undefined
            }}
          />
        </DrawerModal>
      )}
    </>
  )
};

export default TransactionReference;
