import React, {useEffect} from 'react';
import { useLocation } from '@reach/router';
import { parseSearch } from '../../util/url';
import Spinner from '../../components/Spinner';
import { useTruelayer } from '../../hooks/useTrueLayer';

const ConnectBankCallback: React.FC<BR.RoutePathProps> = () => {
  const location = useLocation();
  const {finishBankConnection} = useTruelayer();

  useEffect(() => {
    const {code} = parseSearch(location.search);
    if (code) {
      finishBankConnection(code);
    }
  }, [location, finishBankConnection]);

  return (
    <div className="ConnectBankCallback">
      <Spinner />
    </div>
  );
}

export default ConnectBankCallback;
