import React from 'react';
import formatDate from 'date-fns/format';

import './PeriodRange.scss';
import Icon, { IconKey } from '../Icon';

const FORMAT = 'EEE do MMM';

const PeriodRange: React.FC<{
  className?: string,
  from: Date
  to: Date,
  icon?: IconKey
}> = ({
  children,
  className = '',
  from,
  to,
  icon
}) => {
  return (
    <div className={`PeriodRange ${className}`}>
      {icon && (
        <div className="PeriodRange_Icon">
          <Icon name={icon} />
        </div>
      )}
      <div className="PeriodRange_From">
        {formatDate(new Date(from), FORMAT)}
      </div>
      <div className="PeriodRange_Arrow">
        &rarr;
      </div>
      <div className="PeriodRange_To">
        {formatDate(new Date(to), FORMAT)}
      </div>
    </div>
  )
};

export default PeriodRange;