import { useAccessToken } from '../providers/auth';

const TRUELAYER_CONFIG = {
  client_id: process.env.REACT_APP_TRUELAYER_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_TRUELAYER_REDIRECT_URI
};

const TRUELAYER_SCOPE = ['info','accounts','balance','cards','transactions','direct_debits','standing_orders','offline_access'];
const TRUELAYER_AUTH_ROOT = 'https://auth.truelayer.com';
const AUTH_URL = `${TRUELAYER_AUTH_ROOT}/?response_type=code&client_id=${TRUELAYER_CONFIG.client_id}&scope=${TRUELAYER_SCOPE.join('%20')}&providers=uk-ob-all%20uk-oauth-all&redirect_uri=${TRUELAYER_CONFIG.redirect_uri}`;

export const startBankConnection = (accessToken: string) => {
  return fetch(`${process.env.REACT_APP_API_ROOT}/bank-connections/connect-bank?redirectUri=${encodeURI(TRUELAYER_CONFIG.redirect_uri as string)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(res => res.json());
};

export const finishBankConnection = (accessToken: string, code: string) => {
  return fetch(`${process.env.REACT_APP_API_ROOT}/bank-connections/truelayer-callback`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ code, redirectUri: encodeURI(TRUELAYER_CONFIG.redirect_uri as string) })
  })
    .then(res => res.json());
};

export const useTruelayer = () => {
  const { data: accessToken } = useAccessToken();

  return {
    startBankConnection() {
      window.location.href = AUTH_URL;
      // startBankConnection(accessToken)
      //   .then((response) => {
      //     window.location.href = response.authURL;
      //   })
      //   .catch(console.error);
    },
    finishBankConnection(code: string) {
      finishBankConnection(accessToken, code)
        .then(() => {
          window.location.href = '/accounts';
        })
        .catch(console.error);
    }
  }
};