import React from 'react';

import Icon from '../Icon';
import Link from '../Link';

import './SlideRoute.scss';

type Props = {
  back: {
    label: string
    href: string
  }
  onBack?: () => void
}

const SlideRoute: React.FC<Props> = ({children, back, onBack}) => {
  return (
    <div className='SlideRoute'>
      <div className="SlideRoute_Header">
        <Link to={back.href} className='SlideRoute_Back' onClick={onBack}>
          <Icon name='ChevronLeft' />
          {back.label}
        </Link>
      </div>
      <div className="SlideRoute_Scroller">
        {children}
      </div>
    </div>
  )
};

export default SlideRoute;
