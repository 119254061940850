import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import './RadioToggleInput.scss';

interface Props extends BR.FormControlProps {
  onText?: string
  offText?: string
  onValue?: any
  offValue?: any
}

const parseValue = (value: string): any => {
  let val;
  try {
    val = JSON.parse(value);
  } catch(error) {
    val = value;
  }
  return val;
}

const stringifyValue = (value: any): any => {
  let val;
  try {
    val = JSON.stringify(value);
  } catch(error) {
    val = value;
  }
  return val;
}

const RadioToggleInput: React.FC<Props> = (props) => {
  const {
    register,
    name,
    label,
    id,
    onChange,
    onBlur,
    onFocus,
    validation,
    disabled,
    onText = 'YES',
    offText = 'NO',
    onValue = true,
    offValue = false
  } = props;
  const { formState } = useFormContext();
  const error = formState.errors[name];

  return (
    <Controller
      name={name}
      render={({field}) => {
        const { onChange, value = offValue } = field;

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(parseValue(event.target.value));
        }
        const stringOffValue = stringifyValue(offValue);
        const stringOnValue = stringifyValue(onValue);

        const checked = value !== offValue;

        return (
          <fieldset className='RadioToggleInput_Container'>
            <legend className='RadioToggleInput_Label'>{label}</legend>
            <div className={`RadioToggleInput${checked ? ' -checked' : ''}${disabled ? ' -disabled' : ''}`} id={id}>
              <label className='RadioToggleInput_On'>
                <span className="sr-only">
                  <input type="radio" name={name} onChange={handleChange} value={stringOnValue} disabled={disabled} />
                </span>
                {onText}
              </label>
              <label className='RadioToggleInput_Off'>
                <span className="sr-only">
                  <input type="radio" name={name} onChange={handleChange} value={stringOffValue} disabled={disabled} />
                </span>
                {offText}
              </label>
            </div>
            {error && (
              <p className='RadioToggleInput_ErrorMessage'>{error.message}</p>
            )}
          </fieldset>
        )
      }}
    />
  )
}

export default RadioToggleInput;
