import React from 'react';

import { elements } from '../../util/elements';
import type { As } from '../../util/elements';

type Props = {
  as: As,
  [key: string]: any
}

const Element: React.FC<Props> = ({as, ...props}) => {
  return elements[as](props);
}

export type { As };
export default Element;
