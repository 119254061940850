import { Link as RouterLink, useLocation } from "@reach/router";
import { parseSearch } from "../../util/url";

/**
 * A reach/router <Link /> that includes ?account=<accountId>
 */
const Link: React.FC<any> = ({children, to, ...props}) => {
  const { search: searchString } = useLocation();
  const search = parseSearch(searchString);
  const path = to + (search?.account ? `?account=${search.account}` : '');
  return <RouterLink to={path} {...props}>{children}</RouterLink>
}

export default Link;
