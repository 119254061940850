import { RouteComponentProps, useLocation } from '@reach/router';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import AmountInput from '../../../components/form/AmountInput';
import Input from '../../../components/form/Input';
import SelectInput from '../../../components/form/SelectInput';
import Icon from '../../../components/Icon';
import SlideRoute from '../../../components/SlideRoute';
import { useAccount } from '../../../providers/Account';
import { useAccessToken } from '../../../providers/auth';
import { parseSearch } from '../../../util/url';

import './BudgetSettings.scss';

interface FormInput extends BRData.Budget {
}

type BudgetSettingsProps = BR.SlideRoutePathProps
  & RouteComponentProps
  & {
    budgetId?: string;
    onSubmit?: (values: FormInput, e?: React.BaseSyntheticEvent) => void
}

const BudgetSettings: React.FC<BudgetSettingsProps> = ({back, budgetId, onSubmit}) => {
  const account = useAccount();
  const { search } = useLocation();
  const { newBBankAccountId } = parseSearch(search);

  // incase there is no transaction
  const newBudgetTemplate: BRData.Budget = useMemo(() => ({
    _id: 'new',
    bankAccountId: newBBankAccountId,
    title: '',
    budget: 0,
    transactions: []
  }), [ newBBankAccountId ]);

  // find the budget account
  const budget = account?.budgets?.find(({_id}) => _id === budgetId) || newBudgetTemplate;

  // setup form
  const form = useForm<FormInput>({
    defaultValues: {...budget}
  });
  const { register, handleSubmit } = form;

  // handle form submit
  const { data: accessToken } = useAccessToken();
  const handleFormSubmit: SubmitHandler<FormInput> = useCallback((values: FormInput, e) => {
    const postModel: Omit<BRData.Budget, '_id'> & {_id?: string} = {
      ...values,
      bankAccountId: budget.bankAccountId
    };
    if (values._id === 'new') {
      delete postModel._id;
    }
    const method = values._id === 'new' ? 'POST' : 'PUT';
    const body = values._id === 'new' ? [postModel] : postModel;
    fetch(`${process.env.REACT_APP_API_ROOT}/budgets`, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit?.(res as BRData.Budget, e);
      });
  }, [accessToken, onSubmit, budget.bankAccountId]);

  // handle delete
  const handleDelete = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_ROOT}/budgets`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({_id: budget._id})
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit?.(res as BRData.Budget);
      });
  }, [accessToken, onSubmit, budget._id]);

  // set up page title
  const title = budget._id === 'new'
    ? 'Create budget category'
    : budget.title;

  return (
    <SlideRoute back={back}>
      <Box as='h2' className='text-center'>{title}</Box>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box>
            <Input
              label='Name'
              name='title'
              register={register}
              validation={{
                required: 'Please enter a name',
                minLength: {value: 2, message: 'Please enter at least 2 characters'}
              }}
            />

            <AmountInput
              label='Budget'
              name='budget'
              register={register}
            />

            <SelectInput
              label='Bank account'
              name='bankAccountId'
              register={register}
              disabled={true}
              validation={{
                required: 'Please choose a bank account',
              }}
            >
              <option value={account.account?.account_id}>
                {account?.account?.display_name} {account.account?.provider.display_name}
              </option>
            </SelectInput>

            <Button type='submit' modifiers={['block']}>
              Save
            </Button>
          </Box>
        </form>

        {budget._id !== 'new' && (
          <BlockList>
            <BlockListItem
              hasChevron={false}
              rightIcon={<Icon name='Delete' />}
              onClick={handleDelete}
            >
              Delete saving pot
            </BlockListItem>
          </BlockList>
        )}
      </FormProvider>
    </SlideRoute>
  )
};

export default BudgetSettings;
