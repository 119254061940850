import React, { useCallback, useMemo, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useLocation } from '@reach/router';

import './PendingTransaction.scss';

import Box from '../../../components/Box';
import Button from '../../../components/Button';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import DateInput from '../../../components/form/DateInput';
import Input from '../../../components/form/Input';
import RecurringInput from '../../../components/form/RecurringInput';
import RadioToggleInput from '../../../components/form/RadioToggleInput';
import TransactionAmount from '../../../components/form/TransactionAmount';
import SlideRoute from '../../../components/SlideRoute';
import { useAccessToken } from '../../../providers/auth';
import TransactionReference, { TransactionReferenceValue } from '../../../components/TransactionReference';
import { useActiveAccount } from '../../../hooks/useActiveAccount';
import { parseSearch } from '../../../util/url';
import { useFinancialPeriod, useTransactionData } from '../../../providers/FinancialPeriod';
import DrawerModal from '../../../components/DrawerModal';
import BlockListTransactions from '../../../components/BlockList/BlockListTransactions';

interface FormInput extends BRData.Transaction {
}

type PendingTransactionFormProps = {
  onSubmit: (values: FormInput, e?: React.BaseSyntheticEvent) => void
  transactionId?: string;
}

const PendingTransaction: React.FC<BR.SlideRoutePathProps & PendingTransactionFormProps> = ({back, onSubmit, transactionId}) => {
  // figure out IDs
  const accountId = useActiveAccount();
  const { search } = useLocation();
  const _id = transactionId?.split('__')[0];
  const { newTAmount, newTTitle, newTSubtitle, newTDate } = parseSearch(search);

  // incase there is no transaction
  const newTransactionTemplate: BRData.Transaction = useMemo(() => ({
    _id: 'new',
    account: accountId || '',
    type: 'pending',
    amount: newTAmount ? parseInt(newTAmount) : -100,
    title: newTTitle || null,
    subtitle: newTSubtitle || null,
    date: newTDate ? new Date(newTDate) : null as any,
    recurring: null
  }), [accountId, newTAmount, newTTitle, newTSubtitle, newTDate]);

  // find the transaction
  const { loading, data: {transactions} } = useFinancialPeriod();
  const transaction = useTransactionData(transactionId)?.transaction || newTransactionTemplate;

  // setup form
  const form = useForm<FormInput>({
    defaultValues: {...transaction}
  });
  const { register, handleSubmit, watch, setValue } = form;
  const [ recurring, title, subtitle, amount ] = watch(['recurring', 'title', 'subtitle', 'amount']);

  // handle form submit
  const { data: accessToken } = useAccessToken();
  const handleFormSubmit: SubmitHandler<FormInput> = useCallback((values: FormInput, e) => {
    const value = {
      ...values,
      _id,
      date: values.recurring ? values.lastPaid : values.date
    };
    if (_id === 'new') {
      delete value._id;
    }
    const method = _id === 'new' ? 'POST' : 'PUT';
    const body = _id === 'new' ? [value] : value;
    fetch(`${process.env.REACT_APP_API_ROOT}/transactions`, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit(res as BRData.Transaction, e);
      });
  }, [_id, accessToken, onSubmit]);

  // handle delete transaction
  const handleDeleteTransaction = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_ROOT}/transactions`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({_id})
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit(res as BRData.Transaction);
      });
  }, [onSubmit, _id, accessToken]);

  // handle mark as paid
  const [markAsPaidModalOpen, setMarkAsPaidModalOpen] = useState(false);
  const handleMarkAsPaid = useCallback((transaction: BRData.Transaction) => {
    const value = {
      _id,
      date: transaction.date
    };
    fetch(`${process.env.REACT_APP_API_ROOT}/transactions`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(value)
    })
      .then((res) => res.json())
      .then((res) => {
        setMarkAsPaidModalOpen(false);
        setTimeout(() => {
          onSubmit(res as BRData.Transaction);
        }, 300);
      });
  }, [_id, accessToken, onSubmit]);

  // auto fill form from previous transaction data
  const referenceValue: TransactionReferenceValue = { title, amount, subtitle };
  const handleReferenceChange = (reference: TransactionReferenceValue) => {
    setValue('title', reference.title);
    setValue('amount', reference.amount);
    setValue('subtitle', reference.subtitle);
  };
  
  // get preview values
  const previewValue = { title, subtitle, amount };

  // get page title and paid type
  const paid = !!transaction?.transaction_id;
  const pageTitle = _id === 'new'
      ? 'Create pending transaction'
      : 'Pending transaction';

  return (
    <SlideRoute back={back}>
      <Box as='h2' className='text-center'>{pageTitle}</Box>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className='PendingTransaction_Form'>
          {_id === 'new' && (
            <>
              <Box as='h3'>
                Copy previous transaction
              </Box>
              <TransactionReference
                value={referenceValue}
                transactions={transactions.transactions}
                onChange={handleReferenceChange}
              />
              <Box as='h3'>
                Or create manually
              </Box>
            </>
          )}
          <Box>
            <Input
              label='Title'
              name='title'
              register={register}
              validation={{
                required: 'Please enter a title',
                minLength: {value: 2, message: 'Please enter at least 2 characters'}
              }}
              disabled={paid}
            />
            <Input
              label='Desc.'
              name='subtitle'
              register={register}
              validation={{required: false}}
              disabled={paid}
            />
            <TransactionAmount
              label='Amount'
              name='amount'
              register={register}
              validation={{
                required: 'Please enter an amount'
              }}
              disabled={paid}
            />
            <div className="PendingTransaction_DateField">
              <DateInput
                label='Date'
                name='date'
                register={register}
                validation={{ required: !!recurring ? false : 'Please enter a payment date' }}
                disabled={!!recurring || paid}
              />
              <RadioToggleInput
                label={paid ? 'Make recurring?' : 'Recurring?'}
                name='recurring'
                register={register}
                validation={{ required: false }}
                onValue={transaction?.recurring || '1:day'}
                offValue={null}
              />
            </div>
            {!!recurring && (
              <>
                <DateInput
                  label='Last paid'
                  name='lastPaid'
                  register={register}
                  validation={{ required: 'Please enter a date from which to start the recurring transactions.' }}
                />
                <RecurringInput
                  label='Recurring'
                  name='recurring'
                  register={register}
                  validation={{ required: true }}
                />
              </>
            )}
          </Box>
          <Box as='h3'>
            Preview
          </Box>
          <TransactionReference
            value={previewValue}
          />
          <Box>
            <Button
              type='submit'
              modifiers={['block']}
              disabled={!!loading}
              loading={!!loading}
            >
              Save
            </Button>
          </Box>
        </form>
        {_id !== 'new' && (
          <>
            <BlockList>
              <BlockListItem onClick={handleDeleteTransaction}>
                Delete transaction
              </BlockListItem>
              <BlockListItem onClick={() => setMarkAsPaidModalOpen(true)}>
                Mark as paid
              </BlockListItem>
            </BlockList>
            <DrawerModal open={markAsPaidModalOpen} onClose={() => setMarkAsPaidModalOpen(false)}>
              <BlockListTransactions
                transactions={transactions.transactions}
                transactionProps={{
                  onClick: handleMarkAsPaid,
                  hasChevron: false,
                  as: 'button',
                  to: undefined
                }}
              />
            </DrawerModal>
          </>
        )}
      </FormProvider>
    </SlideRoute>
  )
};

export default PendingTransaction;
