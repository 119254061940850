import React, { useCallback } from 'react';

import './Toggle.scss';

type Props = {
  value: any
  checked: boolean
  onChange: ({value, checked}: {value: any, checked: boolean}) => void
  className?: string
  modifiers?: string[]
}

const Toggle: React.FC<Props> = ({value, checked, onChange, className = '', modifiers}) => {
  const handleChange = useCallback(() => {
    onChange({value, checked: !checked});
  }, [onChange, value, checked]);
  return (
    <label className={`Toggle ${className} ${checked ? ' -checked' : ''}${modifiers?.map(m => ` -${m}`)}`}>
      <span className="sr-only">
        {value}
        <input type='checkbox' value={value} checked={checked} onChange={handleChange} />
      </span>
    </label>
  );
};

export default Toggle;