import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useLocation } from '@reach/router';

import './SavingTransaction.scss';

import Box from '../../../components/Box';
import Button from '../../../components/Button';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import DateInput from '../../../components/form/DateInput';
import Input from '../../../components/form/Input';
import RecurringInput from '../../../components/form/RecurringInput';
import RadioToggleInput from '../../../components/form/RadioToggleInput';
import TransactionAmount from '../../../components/form/TransactionAmount';
import SlideRoute from '../../../components/SlideRoute';
import { useAccessToken } from '../../../providers/auth';
import TransactionReference, { TransactionReferenceValue } from '../../../components/TransactionReference';
import { useActiveAccount } from '../../../hooks/useActiveAccount';
import { parseSearch } from '../../../util/url';
import { useFinancialPeriod, useTransactionData } from '../../../providers/FinancialPeriod';
import TransferAmount from '../../../components/form/TransferAmount';

interface FormInput extends BRData.Transaction {
}

type SavingTransactionFormProps = {
  onSubmit: (values: FormInput, e?: React.BaseSyntheticEvent) => void
  transactionId?: string;
}

const SavingTransaction: React.FC<BR.SlideRoutePathProps & SavingTransactionFormProps> = ({back, onSubmit, transactionId}) => {
  // figure out IDs
  const accountId = useActiveAccount();
  const { search } = useLocation();
  const _id = transactionId?.split('__')[0];
  const { newTAmount, newTSavingAccount } = parseSearch(search);
  const { loading, data: {savings} } = useFinancialPeriod();
  const savingTransaction = useTransactionData(transactionId)?.transaction;
  const savingAccount = savings?.find(({_id}) => _id === newTSavingAccount || _id === savingTransaction?.savingAccount);

  // incase there is no transaction
  const newTransactionTemplate: BRData.Transaction = useMemo(() => ({
    account: accountId || '',
    amount: newTAmount ? parseInt(newTAmount) : null as any,
    date: new Date(),
    lastPaid: new Date(),
    recurring: null,
    savingAccount: savingAccount?._id,
    type: 'saving',

    _id: 'new',
    title: savingAccount?.title,
    subtitle: parseInt(newTAmount) > 0 ? 'Withdrawal' : 'Deposit',
  }), [accountId, newTAmount, savingAccount?._id, savingAccount?.title]);

  const transaction = useTransactionData(transactionId)?.transaction || newTransactionTemplate;

  // setup form
  const form = useForm<FormInput>({
    defaultValues: {...transaction}
  });
  const { register, handleSubmit, watch, setValue } = form;
  const [ recurring, amount ] = watch(['recurring', 'amount']);

  useEffect(() => {
    setValue('subtitle', amount < 0 ? 'Deposit' : 'Withdrawal')
  }, [amount, setValue]);


  // handle form submit
  const { data: accessToken } = useAccessToken();
  const handleFormSubmit: SubmitHandler<FormInput> = useCallback((values: FormInput, e) => {
    const transaction = {
      ...values,
      _id,
      date: values.recurring ? values.lastPaid : values.date
    };
    if (_id === 'new') {
      delete transaction._id;
    }
    const method = _id === 'new' ? 'POST' : 'PUT';
    const body = _id === 'new' ? [transaction] : transaction;
    fetch(`${process.env.REACT_APP_API_ROOT}/transactions`, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit(res as BRData.Transaction, e);
      });
  }, [_id, accessToken, onSubmit]);

  // handle delete transaction
  const handleDeleteTransaction = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_ROOT}/transactions`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({_id})
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit(res as BRData.Transaction);
      });
  }, [onSubmit, _id, accessToken]);
  
  // get preview values
  const previewValue = {
    title: transaction.title,
    subtitle: transaction.subtitle,
    amount
  };

  // get page title
  const pageTitle = amount < 0
    ? `Deposit to`
    : `Withdraw from`;

  return (
    <SlideRoute back={back}>
      <Box as='h2' className='text-center'>
        <small>{pageTitle}</small> <br />
        {savingAccount?.title}
      </Box>
      {/* <BlockHeading>{transaction?.title || '...'}</BlockHeading> */}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className='SavingTransaction_Form'>
          <Box>
            <Input
              label='Title'
              name='title'
              register={register}
              validation={{
                required: 'Please enter a title',
                minLength: {value: 2, message: 'Please enter at least 2 characters'}
              }}
            />
            <Input
              label='Desc.'
              name='subtitle'
              register={register}
              validation={{required: false}}
            />
            <TransferAmount
              label='Amount'
              name='amount'
              register={register}
              validation={{
                required: 'Please enter an amount'
              }}
              disabled={_id !== 'new'}
            />
            <div className="SavingTransaction_DateField">
              <DateInput
                label='Date'
                name='date'
                register={register}
                validation={{ required: !!recurring ? false : 'Please enter a payment date' }}
              />
              <RadioToggleInput
                label={_id === 'new' ? 'Make recurring?' : 'Recurring?'}
                name='recurring'
                register={register}
                validation={{ required: false }}
                onText='Yes'
                offText='No'
                onValue={transaction?.recurring || '1:day'}
                offValue={null}
                disabled={_id !== 'new'}
              />
            </div>
            {!!recurring && (
              <>
                <DateInput
                  label='Starting from'
                  name='lastPaid'
                  register={register}
                  validation={{ required: 'Please enter a date from which to start the recurring transactions.' }}
                  disabled={_id !== 'new'}
                />
                <RecurringInput
                  label='Recurring'
                  name='recurring'
                  register={register}
                  validation={{ required: true }}
                  disabled={_id !== 'new'}
                />
              </>
            )}
          </Box>
          <Box as='h3'>
            Preview
          </Box>
          <TransactionReference
            value={previewValue}
          />
          {_id === 'new' && (
            <Box>
              <Button
                type='submit'
                modifiers={['block']}
                disabled={!!loading}
                loading={!!loading}
              >
                Save
              </Button>
            </Box>
          )}
        </form>
        {_id !== 'new' && (
          <BlockList>
            <BlockListItem onClick={handleDeleteTransaction}>
              Delete transaction
            </BlockListItem>
            <BlockListItem>
              Mark as paid
            </BlockListItem>
          </BlockList>
        )}
      </FormProvider>
    </SlideRoute>
  )
};

export default SavingTransaction;
