import { useNavigate } from '@reach/router';
import React, { useCallback, useMemo } from 'react';
import BlockHeading from '../../../components/BlockHeading';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import BlockListTransactions from '../../../components/BlockList/BlockListTransactions';
import Box from '../../../components/Box';
import PeriodRange from '../../../components/PeriodRange';
import ProgressDial from '../../../components/ProgressDial';
import SlideRoute from '../../../components/SlideRoute';
import SlideTransitionRouter from '../../../components/SlideTransitionRouter';
import Spinner from '../../../components/Spinner';
import { useActiveAccount } from '../../../hooks/useActiveAccount';
import { useFinancialPeriod } from '../../../providers/FinancialPeriod';
import { withCurrency } from '../../../util/currency';
import Transaction from '../Transaction';

import './Budget.scss';

type BudgetProps = {
  budgetId?: string
}

const BACK = {
  label: 'Back to budget',
  href: '../'
};

const Budget: React.FC<BR.SlideRoutePathProps & BudgetProps> = ({back, budgetId}) => {
  const navigate = useNavigate();
  const account = useActiveAccount();
  const { data: {budgets, transactions, balance, from, to} } = useFinancialPeriod();
  const budget = budgets?.find(({_id}) => _id === budgetId);

  const budgetTransactions = useMemo(() => {
    return transactions.transactions.filter(({_id}) => budget?.transactions.includes(_id as any));
  }, [transactions.transactions, budget?.transactions]);

  const handleTransactionSaved = useCallback(() => {
    navigate(`..?account=${account}`);
  }, [navigate, account]);

  if (!budget) {
    return <Spinner />
  }

  const spent = balance.budgets[budget._id];

  return (
    <>
      <SlideRoute back={back}>
        <PeriodRange from={from} to={to} icon='DateRange' />
        <Box as='h2' className='text-center'>{budget.title}</Box>
        <Box className='Budget_Columns'>
          <div className="Budget_Column">
            <div className="Budget_Data">
              <div className="Budget_DataItem">
                <p>Budget</p>
                <p>{withCurrency(budget.budget)}</p>
              </div>
              <div className="Budget_DataItem">
                {spent > 0 ? (
                  <>
                    <p>Deposited</p>
                    <p>{withCurrency(spent)}</p>
                  </>
                ) : (
                  <>
                    <p>Spent</p>
                    <p>{withCurrency(0 - spent)}</p>
                  </>
                )}
              </div>
              <div className="Budget_DataItem">
                <p>Remaining</p>
                <p className='Budget_Remaining'>{withCurrency(budget.budget + spent)}</p>
              </div>
            </div>
          </div>
          <div className="Budget_Column">
            <ProgressDial
              negativeValue={true}
              moreIsBad={true}
              max={budget.budget}
              value={spent}
            />
          </div>
        </Box>
        <BlockList>
          <BlockListItem hasChevron={false} to={`/accounts/${budget.bankAccountId}`}>
            Manage budgets
          </BlockListItem>
        </BlockList>

        <BlockHeading>Transactions</BlockHeading>

        <BlockListTransactions
          transactions={budgetTransactions}
        />
      </SlideRoute>

      <SlideTransitionRouter>
        <Transaction
          path='transaction/:transactionId'
          back={BACK}
          onSubmit={handleTransactionSaved}
        />
      </SlideTransitionRouter>
    </>
  )
};

export default Budget;
