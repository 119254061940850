import { useCallback, useState } from 'react';
import { useAccessToken } from '../providers/auth';

const BASE_URL = `${process.env.REACT_APP_API_ROOT}/`;

type State<T> = {
  error: any,
  loading: boolean,
  data: T
}

interface UseApiOptions extends RequestInit {
}

export const useApi = <T>(path: string, loadingOnMount = false): [State<T>, (options?: UseApiOptions) => void] => {
  const { data: accessToken } = useAccessToken();
  const [state, setState] = useState<State<T>>({
    error: null,
    loading: loadingOnMount,
    data: null as unknown as T,
  });

  const fetcher = useCallback(async (options: UseApiOptions = {}) => {
    if (accessToken && path) {
      setState((s) => ({ ...s, error: null, loading: true, }));
      try {
        const res = await fetch(BASE_URL + path, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(options.body)
        });
        const response = await res.json();
        if (res.ok) {
          setState((s) => ({
            ...s,
            data: response,
            error: null,
            loading: false,
          }));
        } else {
          setState((s) => ({
            ...s,
            error: response.error || response,
            loading: false,
          }));
        }
      }
      catch(error) {
        setState((s) => ({ ...s, error, loading: false }));
      }
    }
  }, [accessToken, path]);

  return [
    {...state},
    fetcher,
  ];
};