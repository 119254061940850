import React, { useContext, useEffect, useMemo, useState } from 'react';
import formatDate from 'date-fns/format';
import { useApi } from '../../api/useApi';

type FinancialPeriodMethods = {
  loadTransactions: () => void
  setRemoveRemainingBudgetsFromBalance: (value: boolean) => void
}

type State = {
  error: any
  loading: boolean
  data: BRData.FinancialPeriodData
  config: CalcBalanceOpts
}

const EMPTY_STATE: State = {
  error: null,
  loading: true,
  data: null as unknown as BRData.FinancialPeriodData,
  config: {}
}

const FinancialPeriodContext = React.createContext<State>(EMPTY_STATE);
const FinancialPeriodMethodsContext = React.createContext<FinancialPeriodMethods>({} as FinancialPeriodMethods);

type CalcBalanceArgs = {
  balance: number
  budgets: BRData.Budget[]
  savings: BRData.Savings[]
  pendingTransactions: BRData.Transaction[]
  savingTransactions: BRData.Transaction[]
  transactions: BRData.Transaction[]
}

type CalcBalanceOpts = {
  removeRemainingBudgetsFromBalance?: boolean
}

const calculateBalance = (args: CalcBalanceArgs, opts: CalcBalanceOpts = {}): BRData.Balance => {
  const {balance, budgets, savings, pendingTransactions, savingTransactions, transactions} = args;
  const {removeRemainingBudgetsFromBalance} = opts;

  const availableBalance: BRData.Balance = {
    accountBalance: balance,
    availableBalance: balance,
    pendingTotal: 0,
    savingTotal: 0,
    budgetTotal: 0,
    savings: savings?.reduce<BRData.Balance['savings']>((acc, val) => {
      acc[val._id] = 0;
      return acc;
    }, {}),
    budgets: budgets?.reduce<BRData.Balance['budgets']>((acc, val) => {
      acc[val._id] = 0;
      return acc;
    }, {})
  };

  pendingTransactions?.forEach((transaction) => {
    if (transaction.amount) {
      availableBalance.availableBalance += transaction.amount;
      availableBalance.pendingTotal += transaction.amount;
    }
  });
  savingTransactions?.forEach((transaction) => {
    if (transaction.savingAccount && transaction.amount) {
      // a transaction IN to the saving account will be a negative balance
      // a transaction OUT of the saving account will be a positive balance
      // this is because it a transaction OUT of the available balance,
      // which would have a negative balance everywhere else
      availableBalance.availableBalance += transaction.amount;
      availableBalance.savingTotal -= transaction.amount;
      availableBalance.savings[transaction.savingAccount] -= transaction.amount;
    }
  });

  budgets?.forEach((budget) => {
    transactions?.forEach((transaction) => {
      if (transaction._id && budget.transactions.includes(transaction._id)) {
        availableBalance.budgets[budget._id] += transaction.amount;
      }
    });
    if (removeRemainingBudgetsFromBalance) {
      const budgetBalance = availableBalance.budgets[budget._id];
      let remainingBudgetBalance = budget.budget + budgetBalance;
      remainingBudgetBalance = remainingBudgetBalance < 0 ? 0 : remainingBudgetBalance;
      availableBalance.availableBalance -= remainingBudgetBalance;
      availableBalance.budgetTotal += remainingBudgetBalance;
    }
  });
  return availableBalance;
}

type FinancialPeriodProviderProps = CalcBalanceOpts & {
  account: string
  balance: number
  from: string
  to: string
  budgets: BRData.Budget[]
  savings: BRData.Savings[]
  includePendingBeforeFromDate?: boolean
  onLoading?: React.ReactNode
  onLoaded?: (financialPeriod: BRData.FinancialPeriodData) => void
}

const FinancialPeriodProvider: React.FC<FinancialPeriodProviderProps> = ({
  account,
  balance,
  from,
  to,
  budgets,
  savings,
  includePendingBeforeFromDate,
  removeRemainingBudgetsFromBalance,
  onLoading,
  onLoaded,
  children
}) => {
  const transactionsApiPath = `transactions?account=${account}&from=${from}&to=${to}` + (includePendingBeforeFromDate ? '&includeAll=true': '');
  const [ transactions, loadTransactions ] = useApi<BRData.Transactions>(transactionsApiPath, true);
  const [ removeRemainingBudgetsFromBalanceState, setRemoveRemainingBudgetsFromBalanceState ] = useState(removeRemainingBudgetsFromBalance);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  const loading = transactions.loading;
  const error = transactions.error;

  const methods = {
    loadTransactions,
    setRemoveRemainingBudgetsFromBalance: setRemoveRemainingBudgetsFromBalanceState
  };
  
  const state = useMemo<State>(() => {
    const availableBalance = calculateBalance({
      balance,
      budgets,
      savings,
      transactions: transactions.data?.transactions,
      pendingTransactions: transactions.data?.pendingTransactions,
      savingTransactions: transactions.data?.savingTransactions,
    }, {
      removeRemainingBudgetsFromBalance: removeRemainingBudgetsFromBalanceState
    });
    return {
      loading,
      error,
      data: {
        from: new Date(from),
        to: new Date(to),
        transactions: transactions.data,
        budgets,
        savings,
        balance: availableBalance
      },
      config: {
        removeRemainingBudgetsFromBalance: !!removeRemainingBudgetsFromBalanceState
      }
    }
  }, [balance, budgets, error, from, loading, removeRemainingBudgetsFromBalanceState, savings, to, transactions.data]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (!state.loading && state.data && !isLoaded && onLoaded) {
      setIsLoaded(true);
      onLoaded(state.data);
    }
  }, [state.loading, state.data, onLoaded, isLoaded]);

  return (
    <FinancialPeriodContext.Provider value={state}>
      <FinancialPeriodMethodsContext.Provider value={methods}>
        {
          state.loading
            ? onLoading
            : children
        }
      </FinancialPeriodMethodsContext.Provider>
    </FinancialPeriodContext.Provider>
  )
};

export const useFinancialPeriod = () => {
  return useContext(FinancialPeriodContext);
};

export const useFinancialPeriodMethods = () => {
  return useContext(FinancialPeriodMethodsContext);
};

export const useTransactionData = (transactionId?: string) => {
  const { loading, data: {transactions} } = useFinancialPeriod();
  const finder = ({_id}: BRData.Transaction) => _id === transactionId;
  const transaction = !transactionId ? undefined :
    (
      transactions.pendingTransactions.find(finder)
      || transactions.savingTransactions.find(finder)
      || transactions.transactions.find(finder)
    );
  return {
    loading,
    transaction
  };
}

export default FinancialPeriodProvider;
