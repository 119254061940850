import React, { useCallback, useEffect } from 'react';

import './Transaction.scss';

import Box from '../../../components/Box';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import SlideRoute from '../../../components/SlideRoute';
import TransactionReference from '../../../components/TransactionReference';
import { useActiveAccount } from '../../../hooks/useActiveAccount';
import SelectInput from '../../../components/form/SelectInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAccessToken } from '../../../providers/auth';
import Button from '../../../components/Button';
import { useFinancialPeriod, useTransactionData } from '../../../providers/FinancialPeriod';

interface FormInput {
  activeBudget?: string
}

type TransactionFormProps = {
  onSubmit: (values: FormInput, e?: React.BaseSyntheticEvent) => void
  transactionId?: string;
  rootPathExtension?: string;
}

const Transaction: React.FC<BR.SlideRoutePathProps & TransactionFormProps> = ({back, onSubmit, transactionId, rootPathExtension}) => {
  const account = useActiveAccount();
  const { data: { budgets } } = useFinancialPeriod();
  const { transaction } = useTransactionData(transactionId);
  const activeBudget = budgets?.find((budget) => budget.transactions.includes(transaction?._id as string));

  const form = useForm({
    defaultValues: {
      activeBudget: activeBudget?._id
    }
  });
  const { register, handleSubmit, reset } = form;

  useEffect(() => {
    reset({
      activeBudget: activeBudget?._id
    });
  }, [activeBudget?._id, reset])

  const { data: accessToken } = useAccessToken();
  const handleFormSubmit: SubmitHandler<FormInput> = useCallback((values: FormInput, e) => {
    fetch(`${process.env.REACT_APP_API_ROOT}/budgets/change-budget?account=${account}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        transactionId,
        budgetId: values.activeBudget
      })
    })
    .then(() => onSubmit(values))
  }, [accessToken, transactionId, account, onSubmit]);

  return (
    <SlideRoute back={back}>
      <Box as='h2' className='text-center'>Transaction details</Box>
      {transaction && (
        <>
          <div className='Transaction_Form'>
            <Box as='h3'>
              Statement preview
            </Box>
            <TransactionReference
              value={transaction}
            />
          </div>
          <FormProvider {...form}>
            <form className="Transaction_Form" onSubmit={handleSubmit(handleFormSubmit)}>
              <Box>
                <SelectInput
                  label='Budget category'
                  name='activeBudget'
                  register={register}
                  validation={{ required: false }}
                >
                  <option value=''>No budget category</option>
                  {budgets?.map((budget) => {
                    return (
                      <option key={budget._id} value={budget._id}>{budget.title}</option>
                    )
                  })}
                </SelectInput>
                <Button type='submit'>Save</Button>
              </Box>
            </form>
          </FormProvider>
          <BlockList>
            <BlockListItem
              linkAs='RouterLink'
              to={`../../pending-transaction${rootPathExtension ? `__${rootPathExtension}` : ''}/new?account=${account}&newTTitle=${transaction.title}&newTAmount=${transaction.amount}&newTSubtitle=${transaction.subtitle}&newTDate=${transaction.date}`}
            >
              Copy to new pending transaction
            </BlockListItem>
          </BlockList>
        </>
      )}
    </SlideRoute>
  )
};

export default Transaction;
