import { RouteComponentProps, useLocation } from '@reach/router';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import BlockList, { BlockListItem } from '../../../components/BlockList';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import AmountInput from '../../../components/form/AmountInput';
import Input from '../../../components/form/Input';
import SelectInput from '../../../components/form/SelectInput';
import Icon from '../../../components/Icon';
import SlideRoute from '../../../components/SlideRoute';
import { useAccount } from '../../../providers/Account';
import { useAccessToken } from '../../../providers/auth';
import { parseSearch } from '../../../util/url';

import './SavingSettings.scss';

interface FormInput extends BRData.Savings {
}

type SavingSettingsProps = BR.SlideRoutePathProps
  & RouteComponentProps
  & {
    savingId?: string;
    onSubmit?: (values: FormInput, e?: React.BaseSyntheticEvent) => void
}

const SavingSettings: React.FC<SavingSettingsProps> = ({back, savingId, onSubmit}) => {
  const account = useAccount();
  const { search } = useLocation();
  const { newSBankAccountId } = parseSearch(search);

  // incase there is no transaction
  const newSavingTemplate: BRData.Savings = useMemo(() => ({
    _id: 'new',
    bankAccountId: newSBankAccountId,
    type: 'flexible',
    title: '',
    target: null,
    incoming: null,
    start: null
  }), [ newSBankAccountId ]);

  // find the saving account
  const savingAccount = account?.savings?.find(({_id}) => _id === savingId) || newSavingTemplate;

  // setup form
  const form = useForm<FormInput>({
    defaultValues: {...savingAccount}
  });
  const { register, handleSubmit } = form;

  // handle form submit
  const { data: accessToken } = useAccessToken();
  const handleFormSubmit: SubmitHandler<FormInput> = useCallback((values: FormInput, e) => {
    const postModel: Omit<BRData.Savings, '_id'> & {_id?: string} = {
      ...values,
      bankAccountId: savingAccount.bankAccountId
    };
    if (values._id === 'new') {
      delete postModel._id;
    }
    const method = values._id === 'new' ? 'POST' : 'PUT';
    const body = values._id === 'new' ? [postModel] : postModel;
    fetch(`${process.env.REACT_APP_API_ROOT}/savings`, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit?.(res as BRData.Savings, e);
      });
  }, [accessToken, onSubmit, savingAccount.bankAccountId]);

  // handle delete
  const handleDelete = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_ROOT}/savings`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({_id: savingAccount._id})
    })
      .then((res) => res.json())
      .then((res) => {
        onSubmit?.(res as BRData.Savings);
      });
  }, [accessToken, onSubmit, savingAccount._id]);

  // set up page title
  const title = savingAccount._id === 'new'
    ? 'Create saving pot'
    : savingAccount.title;

  return (
    <SlideRoute back={back}>
      <Box as='h2' className='text-center'>{title}</Box>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box>
            <Input
              label='Name'
              name='title'
              register={register}
              validation={{
                required: 'Please enter a name',
                minLength: {value: 2, message: 'Please enter at least 2 characters'}
              }}
            />

            <SelectInput
              label='Type'
              name='type'
              register={register}
              validation={{
                required: 'Please choose a type',
              }}
            >
              <option value="flexible">Flexible</option>
              <option value="incoming" disabled>Save % of incoming</option>
              <option value="outgoing" disabled>Rounp up outgoing to nearest £1</option>
            </SelectInput>

            <SelectInput
              label='Bank account'
              name='bankAccountId'
              register={register}
              disabled={true}
              validation={{
                required: 'Please choose a bank account',
              }}
            >
              <option value={account.account?.account_id}>
                {account?.account?.display_name} {account.account?.provider.display_name}
              </option>
            </SelectInput>

            <AmountInput
              label='Target'
              name='target'
              register={register}
            />

            <Button type='submit' modifiers={['block']}>
              Save
            </Button>
          </Box>
        </form>

        {savingAccount._id !== 'new' && (
          <BlockList>
            <BlockListItem
              hasChevron={false}
              rightIcon={<Icon name='Delete' />}
              onClick={handleDelete}
            >
              Delete saving pot
            </BlockListItem>
          </BlockList>
        )}
      </FormProvider>
    </SlideRoute>
  )
};

export default SavingSettings;
